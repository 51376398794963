import { removeItem, setDevices, getItem, removeBulkItems } from "../lib/cookiesManager";
import { setMicAndCamPermissions } from "../redux/actions/stateActions";
import store from '../store';
import Fingerprint2 from 'fingerprintjs2';
import { internalIpV4 } from 'internal-ip';
import ErrorMessage from './ErrorMessage.json';
import PropTypes from 'prop-types';
import { axiosPostCall } from "../services/apisCall";
import CryptoJS from "crypto-js";

// import { getBulkItems, setBulkItems } from '../lib/cookies';

const removeCookiesOnWebEnd = () => {

}

const refreshCookiesOnWebStart = () => {
    setDevices({ webcamEnabled: false, audioEnabled: false });
    removeItem("closeChatPopup")
}

const renewToken = async () => {
    try {
        let sessionToken = getItem("sessionToken");
        await axiosPostCall("renewToken", { sessionToken })

    } catch (error) {
        removeBulkItems(["sessionToken", "exp", "token"]);
        window.location.href = createRedirectPath("/login");
    }
}

const validateText = (text) => {
    return /^(?!\s)[\w\d\s]+$/.test(text);
};
validateText.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired
}

const validateDescription = (desc) => {
    return /^[^</>{}\][\n]*$/.test(desc);
}

validateDescription.propTypes = {
    desc: PropTypes.string.isRequired
}

const ipv4Address = async () => {
    return internalIpV4();
}

const generateFingerprint = async () => {
    const options = {
        excludes: {
            plugins: true,
            localStorage: true,
            adBlock: true,
            screenResolution: true,
            availableScreenResolution: true,
            enumerateDevices: true,
        },
    };

    const components = await Fingerprint2.getPromise(options);
    const values = components.map((component) => component.value);
    const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);

    return fingerprint;
};

const getMicAndCamPermission = () => {
    try {
        if (navigator?.mediaDevices?.getUserMedia !== null) {
            const options = {
                video: true,
                audio: true
            };

            navigator.mediaDevices.getUserMedia(options)
                .then((stream) => {
                    const track = stream.getVideoTracks()[0];
                    track.enabled = false;
                    return
                }).catch((error) => {
                    console.log("getMicAndCamPermission error", error);

                    if (error?.message === "Permission denied") store.dispatch(setMicAndCamPermissions());

                    return
                })
        }
    } catch (error) {
        console.log("error", error)
    }
}

//For handle apis error response message.
const prepareMessageFromError = (error) => {
    return error?.response?.data?.message || error?.message || ErrorMessage?.[4000]
}

//Define prop type of fn.
prepareMessageFromError.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]), // pass complete error or custom message.
}

// // Set user session.
// const setLoginSession = (exp, sessionToken, token) => {
//     return setBulkItems([
//         { key: 'exp', value: exp },
//         { key: "sessionToken", value: sessionToken },
//         { key: "token", value: token },
//     ]);
// }

// setLoginSession.propTypes = {
//     exp: PropTypes.number.isRequired,
//     sessionToken: PropTypes.string.isRequired,
//     token: PropTypes.string.isRequired,
// }

// // Get user session.
// const getLoginSession = () => {
//     const cookiesNameArray = ["exp", "sessionToken"];
//     return getBulkItems(cookiesNameArray)
// }

const encryptMessage = (message, key) => {
    const msg = CryptoJS.AES.encrypt(message, key).toString();
    return msg;
}

const decryptMessage = (encrypterMsg, key) => {
    const bytes = CryptoJS.AES.decrypt(encrypterMsg, key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText || "Invalid E2EE Key";
}

const createRedirectPath = (path) => {
    const domainSub = process.env.REACT_APP_DOMAIN_SUBDIRECTORY;
    return domainSub !== "" && domainSub !== "/" ? `${domainSub}${path}` : path;
}


const matchIsNumeric = (text) => {
    const isNumber = typeof text === 'number'
    const isString = typeof text === 'string'
    return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
}

const IPAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
    }
}

export {
    prepareMessageFromError,
    // setLoginSession,
    // getLoginSession,
    removeCookiesOnWebEnd,
    refreshCookiesOnWebStart,
    renewToken, validateText,
    validateDescription,
    getMicAndCamPermission,
    ipv4Address,
    generateFingerprint,
    encryptMessage,
    decryptMessage,
    createRedirectPath,
    matchIsNumeric,
    IPAddress
};