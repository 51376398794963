import React from 'react';
import { Route } from "react-router-dom";
import PrivateRoute from "./hoc";

// web pages 
import Dashboard from "../pages/WebUI/Dashboard";
import Feedback from "../pages/WebUI/Feedback";
import LoginWithGoogle from "../pages/WebUI/GoogleLogin";
import JoinWebinar from "../pages/WebUI/JoinWebinar";
import List from "../pages/WebUI/ListWebinar";
import Reports from "../pages/WebUI/Reports";
import ScheduleWebinar from "../pages/Common/ScheduleWebinar";
import ViewWebinarDetails from "../pages/WebUI/ViewWeinarDetails";
import Webinar from '../pages/WebUI/Room';
import Profile from "../pages/WebUI/Profile";
import EndWebianr from "../pages/WebUI/EndWebinar";
import EndMeetUp112 from "../pages/WebUI/EndMeetUP112";
import KickPeer from "../pages/WebUI/KickPeer";
import CustomSession from "../pages/Common/CustomSession";
import Help from '../pages/Common/Help';


// mobile pages
import MobileLoginWithGoogle from "../pages/MobleUI/GoogleLogin";
import MobileDashboard from "../pages/MobleUI/Dashboard";
import MobileJoinWebinar from "../pages/MobleUI/JoinWebinar";
import MobileWebinar from "../pages/MobleUI/Room";
import MobileEndWebianr from "../pages/MobleUI/EndWebinar";
import MobileEndMeetUp112 from "../pages/MobleUI/EndMeetUP112";
import MobileProfile from "../pages/MobleUI/Profile";
import MobileFeedback from "../pages/MobleUI/Feedback";
import MobileReports from "../pages/MobleUI/Reports";
import MobileList from "../pages/MobleUI/ListWebinar";
import MobileViewWebinarDetails from "../pages/MobleUI/ViewWeinarDetails";
// import MobileScheduleWebinar from "../pages/MobleUI/ScheduleWebinar";
import MobileKickPeer from "../pages/MobleUI/KickPeer";
import { Outlet } from 'react-router-dom';
import FooterMenu from '../layouts/mobile/FooterMenu';
import Navbar from '../layouts/Navbar';
import WaitingRoom from '../pages/Common/waitingRoom';
import ResponsiveCheck from './responsiceCheck';
import { useState } from 'react';
import JoinLobby from '../pages/Common/joinLobby';
import LeadsDashboard from '../pages/Common/leadsDashboard';
import Spinner from '../layouts/spinner';

const AllRoutes = () => {
    const [isDesktop, /* setIsDesktop */] = useState(window.innerWidth >= 750);

    ResponsiveCheck()

    /* const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTablet = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletMin = useMediaQuery({ query: '(min-width: 992px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    useEffect(() => {
        logger.debug("@Routes.js, mediaType: isDesktopOrLaptop, isBigScreen, isTablet, isTabletMin,isMobile, isPortrait, isLandscape",
            isDesktopOrLaptop, isBigScreen, isTablet, isTabletMin, isMobile, isPortrait, isLandscape);

        dispatch(deviceType({ isDesktopOrLaptop, isBigScreen, isTablet, isTabletMin, isMobile, isPortrait, isLandscape }))

        // eslint-disable-next-line
    }, [isDesktopOrLaptop, isBigScreen, isTablet, isTabletMin, isMobile, isPortrait, isLandscape]);
 */
    return (
        <>
            {/* isDesktopOrLaptop || isBigScreen || isTablet || isTabletMin */ isDesktop ?
                <>
                    <Route element={<><PrivateRoute > <Outlet /> </PrivateRoute> </>}>
                        <Route element={<> <Spinner /> <Navbar />  <Outlet /> </>}>
                            <Route exact index element={<Dashboard />} />
                            <Route exact path="/schedule_meet" element={<ScheduleWebinar />} />
                            <Route exact path="/list" element={<List />} />
                            <Route exact path="/view_meet_details" element={<ViewWebinarDetails />} />
                            <Route exact path="/reports" element={<Reports />} />
                            <Route exact path="/feedback" element={<Feedback />} />
                            <Route exact path="/profile" element={<Profile />} />
                            <Route exact path="/help" element={<Help />} />

                        </Route>
                        <Route exact path="/leads" element={<LeadsDashboard />} />
                    </Route>
                    <Route exact path="/waiting_room" element={<WaitingRoom />} />

                    <Route exact path="/meet" element={<Webinar />} />
                    <Route exact path="/join_meet" element={<JoinWebinar />} />
                    <Route exact path="/end_meet" element={<EndWebianr />} />
                    <Route exact path="/end_meet_up112" element={<EndMeetUp112 />} />
                    <Route exact path="/kick_peer" element={<KickPeer />} />
                    <Route exact path="/login" element={<LoginWithGoogle />} />
                    <Route exact path="/custom_session" element={<CustomSession />} />
                    {/* <Route exact path="/w" element={<WhiteBoard />} /> */}

                    <Route exact path="/join_lobby" element={<JoinLobby />} />
                </>
                :
                <>
                    <Route element={<><PrivateRoute > <Outlet /> </PrivateRoute> </>}>
                        <Route path="/" element={<><FooterMenu /> <Outlet /> </>}>
                            <Route exact index element={<MobileDashboard />} />
                            <Route exact path="/schedule_meet" element={<ScheduleWebinar />} />
                            <Route exact path="/list" element={<MobileList />} />
                            <Route exact path="/reports" element={<MobileReports />} />
                            <Route exact path="/feedback" element={<MobileFeedback />} />
                            <Route exact path="/profile" element={<MobileProfile />} />
                        </Route>
                        <Route exact path="/view_meet_details" element={<MobileViewWebinarDetails />} />
                        <Route exact path="/leads" element={<LeadsDashboard />} />
                    </Route>

                    <Route exact path="/meet" element={<MobileWebinar />} />
                    <Route exact path="/join_meet" element={<MobileJoinWebinar />} />
                    <Route exact path="/end_meet" element={<MobileEndWebianr />} />
                    <Route exact path="/end_meet_up112" element={<MobileEndMeetUp112 />} />
                    <Route exact path="/kick_peer" element={<MobileKickPeer />} />
                    <Route path="/login" element={<MobileLoginWithGoogle />} />
                    <Route exact path="/waiting_room" element={<WaitingRoom />} />
                    <Route exact path="/custom_session" element={<CustomSession />} />
                    <Route exact path="/help" element={<Help />} />
                    <Route exact path="/join_lobby" element={<JoinLobby />} />


                </>
            }
        </>

    )
}
export default AllRoutes;
