import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import { LoadingButton as Button } from '@mui/lab';
import { wsEmit } from '../../../lib/socketIo';
import { LeadsContext } from '../../../context/leadsContext';
import { LEAD_PASS_TIME, SOCKET_ENVENTS } from '../../../config/Enums';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { useNavigate } from 'react-router-dom';
import { configFile } from '../../../config';
import { generateFingerprint, getMicAndCamPermission } from '../../../utils/common';
import randomString from 'random-string';

const { getProtooUrl } = configFile;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const NewLeadDialog = ({ open, setOpen, data }) => {

    const [connectLoading, setConnectLoading] = React.useState(false);
    const [passLoading, setPassLoading] = React.useState(false);
    const [junkLoading, setJunkLoading] = React.useState(false);
    const [tid, setTid] = React.useState("");

    const navigate = useNavigate()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { callId = "", createdAt = new Date() } = data;

    const { socket, name } = React.useContext(LeadsContext)

    const handleConnect = async () => {
        try {
            setConnectLoading(true);
            const response = await wsEmit(socket, SOCKET_ENVENTS?.ACCEPT_CALL, { callId });

            console.log(response);

            const { title = "", description = "", isHostServer, record, autoRecord,
                disableRecordPlayPause, startDate, endDate, waitingRoom, e2ee,
                serverDetails, isWaitingRoom,
                roomId
            } = response;

            const role = "produce";
            const peerId = randomString({ length: 8 }).toLowerCase();
            // const name = randomString({ length: 6 }).toLowerCase();
            const fp = await generateFingerprint();

            const wssUrl = getProtooUrl({ roomId, peerId, serverDetails })

            getMicAndCamPermission();

            const redirectURL = `/meet?roomId=${roomId}&peerId=${peerId}`;
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=${fp}` || null;

            const state = {
                protooUrl, peerId, role, name, title, description, meetType: "meet", record, autoRecord,
                disableRecordPlayPause, startDate, endDate, roomId, waitingRoom, e2ee, isWaitingRoom
            };

            navigate(redirectURL, { state });

            setConnectLoading(false);
            setOpen(false);

            clearInterval(tid)
            setTid("")

        } catch (error) {
            setConnectLoading(false);
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const handlePass = async () => {
        try {
            setPassLoading(true);
            const { message } = await wsEmit(socket, SOCKET_ENVENTS?.PASS_CALL, { callId });

            renderSuccessMessage(message)

            setPassLoading(false);
            setOpen(false);
            clearInterval(tid)
            setTid("")

        } catch (error) {
            setPassLoading(false);
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const handleJunk = async () => {
        try {
            setJunkLoading(true);
            const { message } = await wsEmit(socket, SOCKET_ENVENTS?.MARK_AS_JUNK, { callId });

            renderSuccessMessage(message)

            setJunkLoading(false);
            setOpen(false);
            clearInterval(tid)
            setTid("")
        } catch (error) {
            setJunkLoading(false);
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const formateTime = (ts) => {
        const date = new Date(ts)
        return ` ${date.getHours()}:${date.getMinutes()}`
    }

    React.useEffect(() => {
        let id;
        if (open) {
            id = setTimeout(() => {
                handlePass();
            }, LEAD_PASS_TIME);
            setTid(id)
        }

        return () => clearInterval(id)
    }, [open])

    return (
        <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={open}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            keepMounted
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "flex-start",
                    justifyContent: 'flex-end'
                },
                "& .MuiPaper-root": {
                    margin: '10px',
                    boxShadow: '0px 2px 0px -7px rgba(0,0,0,0.2),0px 1px 38px 3px rgba(0,0,0,0.14),0px 1px 65px 1px rgba(0,0,0,0.12)',
                    minWidth: '400px'
                }
            }}
            hideBackdrop={true}
        >
            <DialogTitle id="responsive-dialog-title" sx={{ color: 'grey.200' }}>
                {"You have a new connection request. Want to connect?"}
            </DialogTitle>

            <DialogContent>
                <DialogContentText sx={{ color: 'grey.400' }}>
                    Call ID: {callId}
                </DialogContentText>
                <DialogContentText sx={{ color: 'grey.300', mt: '-20px', fontWeight: 500, fontSize: '14px' }}>
                    <br /> Time: {formateTime(createdAt)}
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ mb: '10px' }}>
                <Button
                    onClick={handleConnect}
                    autoFocus
                    variant='contained'
                    size='small'
                    color='success'
                    sx={{ color: '#fff' }}
                    loading={connectLoading}
                >
                    Connect
                </Button>

                <Button
                    onClick={handleJunk}
                    autoFocus
                    variant='outlined'
                    size='small'
                    color={'error'}
                    loading={junkLoading}
                >
                    Mark Junk
                </Button>

                <Button
                    autoFocus
                    onClick={handlePass}
                    size='small'
                    color='warning'
                    variant='outlined'
                    loading={passLoading}
                >
                    Pass
                </Button>

                {/*  <Button
                    onClick={handleClose}
                    variant='outlined'
                    sx={{ color: 'grey.400', borderColor: 'grey.400' }}
                    size='small'
                >
                    Close
                </Button> */}
            </DialogActions>
        </Dialog>
    )
}

export default NewLeadDialog