import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import randomString from 'random-string';
import { Box, Modal, Divider, TextField, Typography, IconButton, useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { joinWebianrCheck } from '../../../services/commonApis';
import UrlParse from 'url-parse';
import { getItem, removeBulkItems, setItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import Grid from '@mui/system/Unstable_Grid';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import DashboardHeader from '../../../components/mobile/dashboard/DashboardHeader';
import WebinarList from '../../../components/mobile/dashboard/WebinarList';
import { validateText } from '../../../utils/common';
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import { configFile } from '../../../config';
import MenuIcon from '@mui/icons-material/Menu';

export default function MobileDashboard() {
    const theme = useTheme();
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [join, setJoin] = useState(false);
    const [joinWebinarUrl, setJoinWebinarUrl] = useState("");
    const [homeWebinars, setHomeWebinar] = useState([]);
    const [loadingWList, setLoadingWList] = useState(true)
    const [startWLoading, setStartWLoading] = useState(false)
    const [reStartWLoading, setReStartWLoading] = useState(false)
    const [startRoomId, setStartRoomId] = useState("")
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState(getItem("name") || "")
    const [updateToCookies, setUpdateToCookies] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false);

    const { consoleUrl } = configFile;

    const iFrameBoxRef = useRef(null);

    const openspinnerSx = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "240px",
        height: "100vh",
        zIndex: 999,
        overflow: 'hidden',
        margin: '0px',
        padding: '0px'
    };

    const closeSpinnerSx = {
        position: "absolute",
        top: '17px',
        left: '22px',
        width: "0px",
        height: "0vh",
        zIndex: 999,
    }

    const handleClickOutside = (event) => {
        if (iFrameBoxRef.current && !iFrameBoxRef.current.contains(event.target)) {
            setOpenSpinner(false);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    };

    const handleOpenIframe = () => {
        setOpenSpinner(!openSpinner);
        return document.addEventListener('mousedown', handleClickOutside);
    }
    const navigate = useNavigate();

    // Handle join weninar model submit button click.
    const joinWebinar = async (e) => {
        e.preventDefault();
        setJoinLoading(true)
        if (!joinWebinarUrl) {
            setJoinLoading(false)
            return renderErrorMessage("Enter join webinar Link first!");
        }
        if (!name) {
            setJoinLoading(false)
            return renderErrorMessage("Enter your name!");
        }
        if (!validateText(name)) {
            setNameError(true)
            setJoinLoading(false)
            return renderErrorMessage("Only Letters and Numbers are allowed!");
        }
        try {
            if (updateToCookies) setItem("name", name)
            const urlParser = new UrlParse(joinWebinarUrl, true);
            // let pId = urlParser.query.peerId;
            const rId = urlParser.query.roomId;
            const e2eKey = urlParser.query?.key || "";
            let token = getItem("token");
            let pId = null;
            if (token) {
                let info = jwt(token);
                pId = info?.email;
            }
            if (!pId) pId = peerId;
            if (!rId) {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!");
            }
            if (!pId) pId = peerId;
            let { redirectURL, state } = await joinWebianrCheck(rId, name, pId) || {};
            if (redirectURL) {
                if (e2eKey) {
                    redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`;
                }
                navigate(redirectURL, { state });
                return setJoinLoading(false)
            } else {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setJoinLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    // Fetch schedule webinar list.
    const fetchList = async (from = 0, size = 4, type = "all") => {
        try {
            let res = await axiosGetCall("listWebinar", { from, size, type })
            setLoadingWList(false)
            setHomeWebinar(res?.result)
        } catch (error) {
            setApiError(true)
            setLoadingWList(false);
            renderErrorMessage("Unable to fetch scheduled webinar list!")
        }
    }

    // Change name on join webinar popup.
    const changeName = (value) => {
        setName(value);
        setUpdateToCookies(true)
    }

    // For start scheduled webinar.
    const startWebinar = async (roomId) => {
        setStartRoomId(roomId)
        setStartWLoading(true)
        try {
            let token = getItem("token")
            let info = jwt(token);
            let pId = info?.email;
            setItem("name", info?.name)
            if (!roomId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid room Id!");
            }

            if (!pId) {
                setStartWLoading(false)
                return renderErrorMessage("Invalid token!");
            }

            let { redirectURL, state } = await joinWebianrCheck(roomId, name, pId) || {};
            if (redirectURL) {
                navigate(redirectURL, { state });
                return setStartWLoading(false)
            } else {
                setStartWLoading(false)
                return renderErrorMessage("Invalid link!")
            }
        } catch (error) {
            setStartWLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    // For re-start scheduled webinar.
    const reStartWebinar = async (roomId) => {
        setStartRoomId(roomId)
        setReStartWLoading(true)
        try {
            let res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
            if (res) {
                await startWebinar(roomId);
                // renderSuccessMessage("Your webinar has been re-scheduled!");
                // let updateStatus = homeWebinars.map((item, index) => {
                //     return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
                // })
                // setHomeWebinar(updateStatus)
                setReStartWLoading(false)
            }
        } catch (error) {
            setReStartWLoading(false)
            return renderErrorMessage(error?.message)
        }
    }

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin === consoleUrl) {
                const { session = false, redirectPath, theme = "light" } = event.data;
                if (session) removeBulkItems(["sessionToken", "token", "exp"]);
                if (redirectPath) return window.location.href = redirectPath;
                const checkTheme = getItem("theme");
                if (checkTheme !== theme) setItem("theme", theme);
            }
        });
        fetchList(0, 4, "all");
        return () => document.removeEventListener('mousedown', handleClickOutside);
        // eslint-disable-next-line
    }, [])

    return (<>
        <Box className='MobileWraper'>
            <ToastContainer />

            <Box sx={openSpinner ? openspinnerSx : closeSpinnerSx}
                ref={iFrameBoxRef}
            // onClick={() => handleOpenIframe()}
            >

                {openSpinner ?
                    <Box sx={{
                        position: 'fixed',
                        top: '17px',
                        left: '22px',
                        zIndex: 4
                    }}>
                        <CloseIcon sx={{ color: 'grey.100' }} onClick={() => handleOpenIframe()} />
                    </Box>
                    : <MenuIcon sx={{ color: '#ffffff' }} onClick={() => handleOpenIframe()} />
                }

                <iframe
                    title='console'
                    src={`${consoleUrl}/spinner?k=meet&t=mobile&s=true&spinner=true`}
                    // allow="camera *;microphone *"
                    style={{
                        top: " 0",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        width: "100%",
                        height: "100%",
                        border: '0px',
                        display: openSpinner ? "" : "none"
                    }}
                />

            </Box>
            <Grid sx={{ height: '100vh' }}>
                <Box className="ScrollBlock">
                    <Box className='ContentBlock' sx={{
                        background: theme.palette.mode === 'dark' ? 'background.default' : '#1976D2',
                        height: '100%'
                    }}>
                        {/* Dashboard Header */}
                        <DashboardHeader joinCheck={() => setJoin(true)} />

                        {/* Webinar List */}
                        {/* {homeWebinars.length > 0 && */}
                        <WebinarList
                            homeWebinarsList={homeWebinars}
                            reStartWebinar={reStartWebinar}
                            reStartWLoading={reStartWLoading}
                            startWebinar={startWebinar}
                            startWLoading={startWLoading}
                            startRoomId={startRoomId}
                            loadingWList={loadingWList}
                            apiError={apiError}
                        />
                        {/* } */}
                    </Box>
                </Box>
            </Grid>

        </Box>

        {/* Join webinar modal */}
        <Modal
            open={join}
            onClose={() => setJoin(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'card.main',
                boxShadow: 24,
                borderRadius: '10px',
                p: 4,

            }}>
                <IconButton id="modal-modal-title" onClick={() => setJoin(false)} sx={{
                    display: 'flex',
                    position: 'absolute',
                    justifyContent: 'right',
                    alignItems: 'right',
                    right: 15,
                    top: 10
                }}>
                    <CloseIcon />
                </IconButton>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ color: 'grey.300' }}
                >
                    Join Your Meeting
                </Typography>
                <Divider id="modal-modal-title" sx={{ mt: 3, }} />
                <Typography id="modal-modal-description" sx={{ mt: 2, color: 'grey.400' }}>
                    Meeting Link
                </Typography>
                <TextField
                    sx={{
                        width: '100%',
                        mt: 2,
                        backgroundColor: 'grey.800',
                        borderRadius: '10px',
                        mb: 2
                    }}
                    className="inputRounded"
                    value={joinWebinarUrl}
                    onChange={(e) => setJoinWebinarUrl(e.target.value)}
                    id="filled-hidden-label-small"
                    placeholder="Type or Paste your link here"
                    inputProps={{ sx: { color: "grey.400" } }}
                />

                <Typography sx={{ color: 'grey.400' }}>
                    Name
                </Typography>
                <TextField
                    sx={{
                        width: '100%',
                        mt: 1,
                        backgroundColor: 'grey.800',
                        borderRadius: '10px'
                    }}
                    className="inputRounded"
                    id="filled-hidden-label-small"
                    placeholder="Enter your name "
                    value={name}
                    onChange={(e) => changeName(e.target.value)}
                    onClick={() => setNameError(false)}
                    error={nameError ? true : false}
                    inputProps={{ sx: { color: "grey.400" } }}
                />

                <Grid xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5
                }}>
                    <LoadingButton
                        loading={joinLoading ? true : null}
                        color='success'
                        loadingPosition="center"
                        // startIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={(e) => joinWebinar(e)}
                        sx={{
                            // ml: 1,
                            borderRadius: '10px',
                            width: '100%',
                            textTransform: 'none'
                        }}
                    >
                        Join Now
                    </LoadingButton>

                </Grid>
            </Box>
        </Modal>
    </>
    )
}