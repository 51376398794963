import React, { /*useEffect,*/ useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { useSelector } from 'react-redux';

// @mui
import { Box, TextField, Typography, IconButton, MenuItem, FormGroup, Switch, Button } from "@mui/material"
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";

// components
import { renderErrorMessage } from "../../../components/ToastNotification";
import { validateText, validateDescription } from "../../../utils/common";
import ErrorMessage from "../../../utils/ErrorMessage.json";

const MainSectionInner = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "700px",
  height: "auto",
  backgroundColor: theme.palette.card.main,
  padding: "24px 20px 40px 20px",
  borderRadius: "6px",
  [theme.breakpoints.down('md')]: {
    height: "calc(100vh - 108px)",
    width: "100%",
    backgroundColor: "inherit",
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: "24px",
  fontWeight: 800
}));

const InputOuterBox = styled("div")(() => ({
  paddingTop: "15px",
  display: "flex",
  flexDirection: "column",
  gap: "5px"
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.palette.grey[600]
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  borderRadius: "5px",
}))

const SwitchButton = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ActionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: "14px",
  fontWeight: 400
}));

const EmailBox = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "100%",
  maxHeight: "110px",
  pb: "5px",
  overflow: "hidden",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const EmailItemBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[1000],
  border: "1px solid",
  borderColor: theme.palette.grey[800],
  fontSize: "14px",
  borderRadius: "30px",
  height: "30px",
  padding: "0 4px 0 1rem",
  display: "inline-flex",
  alignItems: "center",
  margin: "0 0.3rem 0.3rem 0",
  color: theme.palette.grey[300],
  gap: "5px"
}))

export default function ScheduleSection1({ setPage, scheduledData, setScheduledData, scheduleLoading, setScheduleLoading, scheduleWebinarSubmit }) {

  const [title, setTitle] = useState("");
  const [des, setDes] = useState("");
  const [record, setRecord] = useState(false);
  const [autoRecord, setAutoRecord] = useState(false);
  const [waitingRoom, setWaitingRoom] = useState(false);
  const [openRoom, setOpenRoom] = useState(false);

  const [sDate, setSDate] = useState(moment().add(15, "minutes").format());
  const [hr, setHr] = useState("0");
  const [min, setMin] = useState("15");
  const [titleError, setTitleError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [inviteVal, setInviteVal] = useState("");
  const [iEmailError, setIEmailError] = useState("");
  const [inviteEmails, setInviteEmails] = useState([]);
  const [meetType, /* setMeetType */] = useState("meet");
  const { isMobile, isPortrait } = useSelector(state => state?.deviceInfo || {});

  useEffect(() => {
    setTitle(scheduledData?.title || "");
    setDes(scheduledData?.des || "");
    setRecord(scheduledData?.record || false);
    setAutoRecord(scheduledData?.autoRecord || false);
    setWaitingRoom(scheduledData?.waitingRoom || false);
    setOpenRoom(scheduledData?.openRoom || false);

    setSDate(scheduledData?.sDate || moment().add(15, "minutes").format());
    setHr(scheduledData?.hr || "0");
    setMin(scheduledData?.min || "15");
    setInviteEmails(scheduledData?.inviteEmails || []);

    // eslint-disable-next-line
  }, [scheduledData]);
 
  const handleChange = (event) => {
    setHr(event.target.value);
  };

  const handleChangeMin = (event) => {
    setMin(event.target.value);
  };

  const hrOptions = Array(24).fill().map((_, i) => {
    return {
      value: `${i}`,
      label: `${i}`,
    }
  });

  const minOptions = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "45",
      label: "45",
    },
    {
      value: "60",
      label: "60",
    }
  ];

  const validateParams = () => {
    if (!title) {
      setTitleError(true);
      return ErrorMessage?.["MEETING_TITLE"];
    }
    if (!validateText(title)) {
      setTitleError(true);
      return ErrorMessage?.["ALLOWED_TEXT"];
    }
    if (!validateDescription(des)) {
      setDescError(true);
      return ErrorMessage?.["VALID_DESCRIPTION"];
    }
    if (!sDate || +new Date(sDate) <= +new Date()) {
      return ErrorMessage?.["VALID_DATE"];
    }
    return "";
  }

  const nextPage = (e) => {
    e.preventDefault();
    const errMessage = validateParams();
    if (errMessage) {
      return renderErrorMessage(errMessage)
    };

    setScheduledData({
      ...scheduledData,
      title,
      des,
      waitingRoom,
      record,
      autoRecord,
      hr,
      min,
      sDate,
      inviteEmails,
      meetType,
      openRoom
    })
    setPage(2);
  }

  const scheduleWebinar = async (e) => {
    e.preventDefault();
    try {
      setScheduleLoading(true);
      const errMessage = validateParams();
      if (errMessage) {
        setScheduleLoading(false);
        return renderErrorMessage(errMessage)
      };

      await scheduleWebinarSubmit({
        ...scheduledData,
        title,
        des,
        waitingRoom,
        record,
        autoRecord,
        hr,
        min,
        sDate,
        inviteEmails,
        meetType,
        openRoom
      });
    } catch (error) {
      setScheduleLoading(false);
      renderErrorMessage(error?.message);
    }
  }

  const handleKeyDown = evt => {
    if (["Enter", "Tab", ",", " "].includes(evt.key) || !evt.key) {
      evt.preventDefault();

      let value = inviteVal.trim();

      if (value && isValid(value)) {
        setInviteEmails([...inviteEmails, inviteVal]);
        setInviteVal("")
      }
    }
  };

  const handleChangeIEmail = (evt) => {
    setInviteVal(evt.target.value)
    setIEmailError(null);
  };

  const handleDelete = item => {
    let data = inviteEmails.filter(i => i !== item)
    setInviteEmails(data)
  };

  const handlePaste = evt => {
    evt.preventDefault();
    try {
      let paste = evt.clipboardData.getData("text");
      //eslint-disable-next-line
      let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
      if (emails) {
        let toBeAdded = emails.filter(email => !isInList(email));

        setInviteEmails([...inviteEmails, ...toBeAdded])
      }
    } catch (error) {
      renderErrorMessage(ErrorMessage?.["INVALID_DATA"])
    }

  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setIEmailError(error)
      return false;
    }

    return true;
  }

  const isInList = (email) => {
    return inviteEmails.includes(email);
  }

  const isEmail = (email) => {
    //eslint-disable-next-line
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }

  return (
    <>
      <ToastContainer />
      <MainSectionInner>

        {!(isMobile || isPortrait) && <HeadingText>
          Schedule Meeting
        </HeadingText>}

        {/* Title section */}
        <InputOuterBox sx={{ pt: "20px" }}>

          <InputLabel>
            Title
          </InputLabel>

          <CustomTextField
            onChange={(e) => setTitle(e.target.value)}
            onClick={() => setTitleError(false)}
            value={title}
            id="name"
            placeholder="Enter meeting title"
            size="small"
            error={titleError ? true : false}
            inputProps={{ sx: { color: "grey.400" } }}
          />

        </InputOuterBox>

        {/* Description section */}
        <InputOuterBox>

          <InputLabel>
            Description
          </InputLabel>

          <CustomTextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            placeholder="Enter description..."
            onChange={(e) => setDes(e.target.value)}
            onClick={() => setDescError(false)}
            value={des}
            error={descError ? true : false}
            inputProps={{ sx: { color: "grey.600" } }}
          />

        </InputOuterBox>

        {/* Invite as host section */}
        <InputOuterBox>
          <InputLabel>
            Invite as Host
          </InputLabel>

          <EmailBox>
            {inviteEmails.map(item => (
              <EmailItemBox
                // className="tag-item-schedule"
                key={item}
              >
                {item}
                <IconButton
                  type="button"
                  disableFocusRipple
                  disableRipple
                  // className="button"
                  sx={{
                    backgroundColor: "grey.500",
                    height: "20px",
                    width: "20px",
                    color: "grey.1000"
                  }}
                  onClick={() => handleDelete(item)}
                >
                  &times;
                </IconButton>
              </EmailItemBox>
            ))}
          </EmailBox>

          <CustomTextField
            hiddenLabel
            id="filled-hidden-label-small"
            variant="outlined"
            size="small"
            placeholder="Type or paste email addresses and press `Enter` or ,"
            value={inviteVal}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => handleChangeIEmail(e)}
            onPaste={(e) => handlePaste(e)}
            error={iEmailError ? true : false}
            // disabled={meetType === "meet" ? true : false}
            onBlur={(e) => inviteVal ? handleKeyDown(e) : ""}
            inputProps={{ sx: { color: "grey.400" } }}
          />
          {iEmailError && <Typography color="error" sx={{ mt: 1 }}>{iEmailError}</Typography>}

        </InputOuterBox>

        {/* Actions */}
        <InputOuterBox>
          <InputLabel>
            Actions
          </InputLabel>

          <FormGroup sx={{ flexDirection: "row", gap: "15px" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SwitchButton
                inputProps={{ "aria-label": "ant design" }}
                checked={record}
                onChange={() => setRecord(!record)}
              />
              <ActionTypography>Enable start and stop recording</ActionTypography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <SwitchButton
                inputProps={{ "aria-label": "ant design" }}
                checked={autoRecord}
                onChange={() => setAutoRecord(!autoRecord)}
              />
              <ActionTypography>Auto recording</ActionTypography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <SwitchButton
                inputProps={{ "aria-label": "ant design" }}
                checked={waitingRoom}
                onChange={() => setWaitingRoom(!waitingRoom)}
              />
              <ActionTypography>Waiting Room</ActionTypography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <SwitchButton
                inputProps={{ "aria-label": "ant design" }}
                checked={openRoom}
                onChange={() => setOpenRoom(!openRoom)}
              />
              <ActionTypography>Open Room</ActionTypography>
            </Stack>

          </FormGroup>
        </InputOuterBox>

        {/* Duration & Date section */}
        <InputOuterBox sx={{
          flexDirection: {
            sm: "column",
            xl: "row",
            xs: "column",
            md: "row",
            lg: "row"
          }
        }}>
          <InputOuterBox sx={{
            width: {
              sm: "100%",
              xl: "calc(100% / 2)",
              xs: "100%",
              md: "calc(100% / 2)",
              lg: "calc(100% / 2)"
            },
            paddingTop: "0px",
          }}>
            <InputLabel>
              Start Date and Time
            </InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={1} >
                <DateTimePicker
                  renderInput={(props) =>
                    <CustomTextField
                      size="small"
                      {...props}
                    />}
                  value={sDate}
                  onChange={(newValue) => {
                    setSDate(newValue);
                  }}
                  minDate={new Date()}
                  inputProps={{ sx: { color: "grey.400", } }}
                  PopperProps={{
                    sx: {
                      ".MuiPaper-root": {
                        backgroundColor: "card.main",
                        color: "grey.400"
                      },
                      ".MuiPickersDay-root": {
                        backgroundColor: "card.main",
                        color: "grey.400"
                      },
                      ".MuiClockNumber-root": {
                        color: "grey.400"
                      },
                    },
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </InputOuterBox>

          <InputOuterBox sx={{
            width: {
              sm: "100%",
              xl: "calc(100% / 2)",
              xs: "100%",
              md: "calc(100% / 2)",
              lg: "calc(100% / 2)"
            },
            paddingTop: "0px",
          }}>
            <InputLabel>
              Duration
            </InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={0.7} direction="row">
                <CustomTextField
                  id="outlined-select-currency"
                  select
                  label="hr"
                  value={hr}
                  onChange={handleChange}
                  size="small"
                  sx={{
                    width: "50vh"
                  }}
                  inputProps={{ sx: { color: "grey.600" } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "card.main",
                          color: "grey.600"
                        },
                      }
                    }
                  }}
                >
                  {hrOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>


                <CustomTextField
                  id="outlined-select-currency"
                  select
                  label="min"
                  value={min}
                  size="small"
                  onChange={handleChangeMin}
                  sx={{ width: "100%" }}
                  inputProps={{ sx: { color: "grey.600" } }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "card.main",
                          color: "grey.600",
                        },
                      }
                    }
                  }}
                >
                  {minOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextField>

              </Stack>
            </LocalizationProvider>
          </InputOuterBox>

        </InputOuterBox>

        {/* Action button */}
        <Box sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row"
          },
          justifyContent: "space-between",
          gap: "10px"
        }}>
          <LoadingButton
            variant="outlined"
            color="success"
            loading={scheduleLoading ? true : false}
            loadingPosition="center"
            onClick={(e) => scheduleWebinar(e)}
            sx={{
              width: "100%",
              mt: 4,
              mb: 0,
              textTransform: "none"
            }}
          >
            Skip & Schedule
          </LoadingButton>
          <Button
            variant="contained"
            loadingPosition="center"
            onClick={(e) => nextPage(e)}
            sx={{
              width: "100%",
              mt: {
                xs: 0,
                sm: 0,
                md: 4,
                lg: 4,
                xl: 4
              },
              mb: 0,
              textTransform: "none"
            }}
          >
            Next
          </Button>
        </Box>
      </MainSectionInner>
    </>
  )
}