import React, { useState, useEffect } from 'react';

// @mui
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { styled } from '@mui/material/styles';
// import InfiniteScroll from 'react-infinite-scroll-component';

// components
// import NetworkError from '../Error/networkError';
import BannerWebinarDefaultBlack from '../../../assets/svgs/bannerWebinarDefaultBlack';

import propTypes from 'prop-types'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[0],
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const StyledBannerBox = styled('div')(({ theme }) => ({
  width: '50px',
  mixBlendMode: "luminosity",
  borderRadius: '100px',
  [theme.breakpoints.up('md')]: {
    height: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '20px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '30px',
  },
}));

const PaginationTable = ({
  // size,
  // loadMoreData,
  ActionJsx,
  isLoading,
  // setLoad,
  // error = false,
  rows,
  columns,
  maxHeight = 680
}) => {
  const theme = useTheme()
  const [rowData, setRowData] = useState([]);
  // const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setRowData(rows || []);
  }, [rows]);

  // const loadPaginationData = async () => {
  //   const data = await loadMoreData();
  //   // if (data.length < size) setHasMore(false);
  //   setRowData([...rowData, ...data]);
  // }

  const userIdsJsx = (id, align, value, rowData) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.600',
        borderRight: '1px solid',
        borderColor: 'grey.300',
        borderBottom: 'none'
      }}>
      <AvatarGroup
        total={rowData.totalUserIds}
        max={3}
        sx={{
          '&.MuiAvatarGroup-root .MuiAvatar-root': {
            border: '1px solid',
            borderColor: 'grey.700',
            color: "grey.400",
            backgroundColor: 'grey.800',
            width: '20px',
            height: '20px',
            fontSize: '12px',
          }
        }}>
        {value.map(({ email }, index) => {
          return <Avatar
            key={index}
            sx={{
              width: '20px',
              height: '20px',
              fontSize: '12px',
            }}
            alt={email?.toUpperCase()}
            src="avatar1.jpg" />
        })}
      </AvatarGroup>
    </TableCell>
  )

  const WebinarListActions = (id, align, value, rowInfo) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        borderBottom: 'none'
      }}>
      <ActionJsx
        rowData={rowInfo}
      />
    </TableCell>
  )

  const ReportActions = (id, align, value, rowInfo, totalRecordingSize) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        borderBottom: 'none'
      }}>
      <ActionJsx
        recordings={value}
        totalRecordingSize={totalRecordingSize}
      />
    </TableCell>
  )

  const bannerJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.600',
        borderRight: '1px solid',
        borderColor: 'grey.300',
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'center'
      }}>
      <StyledBannerBox>
        <BannerWebinarDefaultBlack />
      </StyledBannerBox>
    </TableCell>
  )

  const defaultRowJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.600',
        borderRight: '1px solid',
        borderColor: 'grey.300',
        borderBottom: 'none'
      }}>
      {value}
    </TableCell>
  )

  const tableRowData = {
    userIds: userIdsJsx,
    webinarListActions: WebinarListActions,
    banner: bannerJsx,
    recordings: ReportActions,
  }

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        backgroundColor: 'card.main',
        borderBottomLeftRadius: rowData.length ? "8px" : "none",
        borderBottomRightRadius: rowData.length ? "8px" : "none",
        borderBottom: rowData.length ? "1px solid" : "",
        borderTopLeftRadius: "none",
        borderTopRightRadius: "none",
        borderRadius: '0px',
        borderColor: 'grey.300'
      }}>
      {/* <InfiniteScroll
        dataLength={size}
        next={loadPaginationData}
        hasMore={true}
        // loader={<div className="loader" key={0}>Loading ...</div>}
        // endMessage={<div>No More Data..</div>}
        children={TableContainer}
      > */}
      <TableContainer sx={{
        maxHeight,
        '&::-webkit-scrollbar': {
          width: 0,
        }
      }}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead >
            <TableRow >
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                  sx={{
                    backgroundColor: theme.palette.mode === "light" ? "rgba(241, 243, 244, 1)" : '#191919',
                    color: 'grey.600',
                    borderRight: index === columns.length - 1 ? 'none' : '1px solid',
                    borderColor: 'grey.300',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowData.map((row, index) => (
              <StyledTableRow
                role="checkbox"
                tabIndex={-1}
                key={index}
              >
                {columns.map(({ id, align }) => (tableRowData?.[id] || defaultRowJsx)(
                  id,
                  align,
                  row[id],
                  row, // For Webinar List Actions
                  row["totalRecordingSize"] // For Report recording total size
                ))}
              </StyledTableRow>
            ))}
          </TableBody>

        </Table>

      </TableContainer>
      {/* </InfiniteScroll> */}
      {!(isLoading || rowData.length) && <Box sx={{
        height: {
          xs: "480px",
          sm: "480px",
          md: "480px",
          lg: "706px",
          xl: "706px",
        },
        display: 'flex',
        justifyContent: 'center',
        borderRadius: "12px",
        backgroundColor: 'card.main',
      }}>
        <Box sx={{
          display: 'flex',
          height: "100%",
          width: "100%",
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Typography
            sx={{
              color: 'grey.600',
              fontWeight: 400,
              fontSize: '18px'
            }} >
            No Data Found
          </Typography>
        </Box>
      </Box>}
    </Paper >
  )
}

PaginationTable.propTypes = {
  // error:propTypes.Boolean,
  rows: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  maxHeight: propTypes.object,
}

export default PaginationTable;
