
// eslint-disable-next-line
const ArrowDownSFillIcon = ({ color }) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16L6 10H18L12 16Z" fill={color} />
        </svg>

    )
}

export default ArrowDownSFillIcon