import React from 'react';
import Heading from './components/heading';
import ImageComp from './components/imageComp';
import ListComp from './components/list';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

const Forgot = () => {
  const theme = useTheme()
  return (
    <>
      <Heading
        text='Forgot Password'
      />

      <ListComp

        data={[{
          name: <Typography>
            In case you forgot your password, you can reset it by clicking <span style={{ color: theme.palette.grey[600], textDecoration: 'none' }}>  ‘Forgot Password’</span>on the login page.
          </Typography>,
          id: '12',
        },
        {
          name: 'Here, enter the email you have registered yourself with.',
          id: '12',

        },
        {
          name: <Typography>
            Now, click on the <span style={{ color: theme.palette.grey[600], textDecoration: 'none' }}>‘Forgot Password’</span>button.
          </Typography>,
          id: '15'
        },
        {
          name: 'A password reset link will be sent to your registered email address.',
          id: '4',

        },
        {
          name: 'Click on that link and enter a new password.',
          id: '5',

        },

        ]}
      />

      <ImageComp
        name={"forgotPassword"}

      />

    </>
  );

};

export default Forgot;






