import React, { useState } from 'react';
import { Box, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { joinWebianrCheck } from '../../../services/commonApis';
import { getItem } from '../../../lib/cookiesManager';
import jwt from 'jwt-decode';
import LoaderOnList from '../components/LoaderOnList';
import NoMoreDataShow from '../components/NoMoreDataShow';
import ApiErrorImgShow from '../components/ApiErrorImgShow';
// import { axiosPostCall } from '../../../services/apisCall';

export default function WebinarList({ wList, loadingWList, apiError, type }) {
  const navigate = useNavigate();
  const [startWLoading, setStartWLoading] = useState(false);
  // const [reStartWLoading, setReStartWLoading] = useState(false);
  const [startRoomId, setStartRoomId] = useState("");

  // For start scheduled webinar.
  const startWebinar = async (roomId) => {
    setStartRoomId(roomId)
    setStartWLoading(true)
    try {
      let token = getItem("token")
      let info = jwt(token);
      let pId = info?.email;
      if (!roomId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid room Id!");
      }

      if (!pId) {
        setStartWLoading(false)
        return renderErrorMessage("Invalid token!");
      }

      let { redirectURL, state } = await joinWebianrCheck(roomId, info?.name, pId) || {};
      if (redirectURL) {
        navigate(redirectURL, { state });
        return setStartWLoading(false)
      } else {
        setStartWLoading(false)
        return renderErrorMessage("Invalid link!")
      }
    } catch (error) {
      setStartWLoading(false)
      return renderErrorMessage(error?.message)
    }
  }

  /* // For re-start scheduled webinar.
  const reStartWebinar = async (roomId) => {
    setStartRoomId(roomId)
    setReStartWLoading(true)
    try {
      const res = await axiosPostCall("scheduleWebinar", { roomId, status: "inactive" })
      if (res) {
        await startWebinar(roomId);
        // renderSuccessMessage("Your meeting has been re-scheduled!");
        // let updateStatus = wList.map((item, index) => {
        //   return item?.roomId === roomId ? { ...item, status: "inactive" } : item;
        // })
        // setWList(updateStatus)
        setReStartWLoading(false)
      }
    } catch (error) {
      setReStartWLoading(false)
      return renderErrorMessage(error?.message)
    }
  } */

  return (
    <>
      {wList.length > 0 && wList.map(({ title, startDate, endDate, canceled, status, roomId }, index) => (
        <Box className='ListCardsGroup' key={index} sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: "4px",
        }}>
          <Typography className='ListDateLable' sx={{
            fontSize: '10px',
            color: 'grey.600',
            fontWeight: '600',
            pl: '8px',
          }}> {new Date(startDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ?
            "Today"
            :
            `${moment(startDate).format('MMMM')} ${new Date(startDate).getDate()}, ${moment(startDate).year()}`}
          </Typography>
          <Box className='ListMeetingCard' sx={{
            px: '14px',
            py: '16px',
            backgroundColor: 'card.main',
            borderRadius: '10px',
            // borderColor: '#D3D8EB',
            borderWidth: '1px',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            gap: "14px",
          }}>
            <Box className='ListCardHeader' sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              color: 'grey.500',
            }}>
              <Typography sx={{
                fontSize: '13px',
                fontWeight: '600',
                lineHeight: '16px',
              }} onClick={() => navigate(`/view_meet_details`, { state: { roomId } })} >
                {title.charAt(0).toUpperCase() + title.slice(1) || ""}
              </Typography>


              {(endDate >= +new Date() && !canceled) && (
                <LoadingButton
                  variant='contained'
                  size='small'
                  loading={startWLoading && startRoomId === roomId ? true : null}
                  loadingPosition="center"
                  onClick={() => startWebinar(roomId)}
                  sx={{
                    textTransform: 'none',
                    boxShadow: 'none',
                    minWidth: '88px',
                  }}
                >
                  Start
                </LoadingButton>
              )}

            </Box>

            <Box className='ListCardBody' sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              color: 'grey.600',
            }}>
              <Box className='ListMeetTime'>
                <Typography sx={{
                  fontSize: '11px',
                  fontWeight: '500',
                  color: 'grey.500'
                }}>Start/End Time:</Typography>
                <Typography sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                }}>{`${moment(startDate).format("hh:mm a")} to ${moment(endDate).format("hh:mm a")}`}</Typography>
              </Box>
              <Box className='ListMeetId' sx={{ pr: '5px' }}>
                <Typography sx={{
                  fontSize: '11px',
                  fontWeight: '500',
                  color: 'grey.500',
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: "flex-end",
                  // flex: 1
                }}>Room ID:</Typography>
                <Typography sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                }}>{roomId || ""}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}

      {/* show loader when data loading. */}
      {loadingWList && <LoaderOnList />}

      {/* Show image and text when no more data available. */}
      {(!wList.length && !loadingWList && !apiError) && <NoMoreDataShow type={type} />}

      {/* Show network image when api throw error or any other error */}
      {(apiError) && <ApiErrorImgShow />}
    </>
  )
}
