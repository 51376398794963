import React, { useEffect, useState } from 'react';
import moment from 'moment';

// @mui
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

// components
import { renderErrorMessage } from '../../../components/ToastNotification';
import { axiosGetCall } from '../../../services/apisCall';
import CustomTabPanel from '../../../components/web/TabPanel/customTabPanel';
import PaginationTable from '../../../components/web/Tables/paginationTable';
import WebinarActionsJsx from '../../../sections/web/ListWebinar/webinarActions';

const MainSection = styled('div')(() => ({
  padding: '50px 10px 10px 70px',
  height: '100vh',
  // height: "calc(100vh - 108px)",
  width: "100%",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 500,
  color: theme.palette.grey[800]
}));

const TableSection = styled('div')(({ theme }) => ({
  borderRadius: "12px",
  background: theme.palette.card.main, // "#2C2D2F",
  overflow: 'hidden',
  boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
  border: '1px solid',
  color: theme.palette.grey[300],
  [theme.breakpoints.up('md')]: {
    height: '615px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '615px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '801px',
  },
}));

export default function List() {
  const [wList, setWList] = useState([]);
  const [{ skip, size }, setListRange] = useState({ skip: 0, size: 100 });
  const [loadingWList, setLoadingWList] = useState(true)
  const [active, setActive] = useState("all");
  // const [apiError, setApiError] = useState(false);

  useEffect(() => {
    (async () => {
      setLoadingWList(true)
      setWList([])
      setListRange({ skip: 0, size: 100 })
      const listData = await fetchList(0, 100, active);
      setWList(listData);
    })();
    //eslint-disable-next-line
  }, [active]);

  const modifyListData = (webinars) => {
    return webinars.map((webinar) => {
      const date = moment(webinar.startDate);
      const duration = webinar.duration;
      return {
        ...webinar,
        banner: "",
        duration: duration ? duration + ' Min' : '---',
        date: date.format('D MMM, YYYY'),
        time: date.format('h:mm A'),
      };
    })
  }

  const columns = [
    { id: 'title', label: 'Title', minWidth: 200, align: 'center' },
    { id: 'date', label: 'Date', minWidth: 110, align: 'center' },
    { id: 'time', label: 'Time', minWidth: 110, align: 'center' },
    { id: 'userIds', label: 'Speakers', minWidth: 110, align: 'center' },
    { id: 'duration', label: 'Duration', minWidth: 110, align: 'center' },
    { id: 'roomId', label: 'RoomId', minWidth: 110, align: 'center' },
    { id: 'webinarListActions', label: 'Actions', minWidth: 20, align: 'center' },
  ];

  // Fetch schedule webinar list.
  const fetchList = async (skip = 0, size = 10, type = "all", search = null) => {
    try {
      let data = { from: skip, size, type };
      if (search) data.search = search;
      let res = await axiosGetCall("listWebinar", data);
      setListRange({ skip: skip + res?.result.length, size });
      setLoadingWList(false);
      return modifyListData(res?.result)
      // setWList([...wList, ...modifiedListData]);
    } catch (error) {
      // setApiError(true)
      setLoadingWList(false)
      renderErrorMessage("Unable to fetch scheduled webinar list!")
    }
  }

  // const search = (e) => {
  //   if (e.key === 'Enter' && (e.target.value).trim() !== "") {
  //     setActive("all");
  //     setLoadingWList(true);
  //     setWList([])
  //     let txt = e.target.value
  //     let searchText = txt.toString().trim()
  //     fetchList(0, 20, "all", searchText);
  //   }
  // }

  const loadMoreData = async () => {
    setLoadingWList(true);
    return await fetchList(skip, size, active);
  }

  const ActionJsx = ({ rowData }) => (
    <WebinarActionsJsx rowData={rowData} active={active} size={size} fetchList={fetchList} setWList={setWList} />
  )

  return (
    <MainSection>
      <ToastContainer />
      <Box sx={{
        padding: "18px 10px",
        // '&::-webkit-scrollbar': {
        //   width: 0,
        // }
      }}>

        <Heading>
          List of Meetings
        </Heading>

      </Box>

      <TableSection>
        <CustomTabPanel setActive={setActive} />
        <PaginationTable
          size={size}
          loadMoreData={loadMoreData}
          ActionJsx={ActionJsx}
          isLoading={loadingWList}
          // setLoad={setLoadingWList}
          // error={"networkError"}
          rows={wList}
          columns={columns}
          maxHeight={{
            md: "566px",
            lg: "566px",
            xl: "767px"
          }}
        />
      </TableSection>
    </MainSection>
  )
}