import React, { memo, useEffect, useState } from 'react';
import { ListItemIcon, Button, Box, Tooltip, styled, tooltipClasses, Zoom, Typography } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VideocamIcon from '@mui/icons-material/Videocam';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import CloseIcon from '@mui/icons-material/Close';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import LoadingButton from '@mui/lab/LoadingButton';
import * as cookiesManager from '../../../lib/cookiesManager';
import EndWebinarIconMenu from './Menu/EndWebinarIconMenu';
import LeaveWebinarModal from './Modal/LeaveWebinarModal';
import EndWebinarModal from './Modal/EndWebinarModal';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';

// import Logger from '../../../../config/Logger';
import DevicesMenu from './Menu/DevicesMenu';
import { useSelector } from 'react-redux';
import { configFile } from '../../../config';
import MicAndVideoPermissionBlock from './Modal/MicAndVideoPermissionBlock';
import ManageAllExternelCases from './ManageAllExternelCases';
import { endRoom } from '../../../services/commonApis';
import { createRedirectPath } from '../../../utils/common';

const { removeMicOnWarningTime, showMicOnWarningTime } = configFile;
// const logger = new Logger("MobileFooter")

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 210,
        backgroundColor: '#737070',
    },
});


function FooterWebinar({ micState, webcamState, roomClient, openParticipantsTab, participantsTabStatus, openChatTab, chatTabStatus, endSuccessLoading, endWebinar, isNewMessage }) {
    const [disableWebCamIcon, setDisableWebCamIcon] = useState(false);
    const [anchorElEnd, setAnchorElEnd] = useState(null);
    const [openEndAll, setOpenEndAll] = useState(false);
    const [openLeave, setOpenLeave] = useState(false);
    const [anchorElDevices, setAnchorElDevices] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [deviceMenu, setDeviceMenu] = useState({ type: 'video', title: "Video" });
    const roomState = useSelector(state => state.room.state)
    const { meetType } = useSelector(state => state.webinarInfo);
    const peersArray = useSelector(state => state.peers);
    const peersCount = Object.keys(peersArray).length

    const micAndCamPermissions = useSelector(state => state.micAndCamPermissions);
    const { promoteToHost } = useSelector(state => state.media);
    const [micBlock, setMicBlock] = useState(true)
    const [cameraBlock, setCameraBlock] = useState(true)
    const [permissionBlock, setPermissionBlock] = useState(false)
    const location = useLocation();
    const [intervalId, setIntervalId] = useState("");


    const { roomId = "" } = location?.state || {};


    // const scrollInto = useRef(null)
    const handleClickEnd = (event) => {
        if (!event) return setAnchorElEnd(null)
        setAnchorElEnd(event.currentTarget)
    };

    const handleCloseEnd = () => {
        setAnchorElEnd(null)
    };

    const btnSx = {
        minWidth: 44,
        height: 44,
        borderRadius: '50%',
        color: '#fff',
    }
    const handleClickVideoDevices = (event, title, type) => {
        event.stopPropagation();
        setAnchorElDevices(event.currentTarget);
        setDeviceMenu({ title, type });
        // this.setState({ anchorElDevices: event.currentTarget, deviceMenu: { title, type } })
    }

    // Checking mic and camera permission in case of permission block
    const checkPermission = async () => {
        try {
            const PermissionStatus = await navigator.permissions.query({
                name: "microphone",
            });
            const cameraPermission = await navigator.permissions.query({
                name: "camera",
            });

            if (PermissionStatus.state === "denied") {
                setMicBlock(false)
            }
            if (cameraPermission.state === "denied") {
                setCameraBlock(false)
            }
            return
        } catch (error) {
            console.error(error);
            return
        }
    }

    const handleEnableMic = async () => {
        if (!micAndCamPermissions?.mic || !micBlock) {
            await checkPermission()
            return setPermissionBlock(true)
        }
        if (micState === 'on') {
            cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: false });
            roomClient.muteMic()
        } else {
            setIsSpeaking(false)
            cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: true });
            roomClient.unmuteMic();
        }
    }

    const handleEnableCam = async () => {
        if (!micAndCamPermissions?.camera || !cameraBlock) {
            await checkPermission()
            return setPermissionBlock(true)
        }
        if (webcamState === 'on') {
            cookiesManager.setDevices({ webcamEnabled: false, audioEnabled: cookiesManager.getDevices().audioEnabled });
            roomClient.disableWebcam();
        }
        else {
            cookiesManager.setDevices({ webcamEnabled: true, audioEnabled: cookiesManager.getDevices().audioEnabled });
            if (disableWebCamIcon) {
                return;
            }
            roomClient.enableWebcam();
            setDisableWebCamIcon(true)
            setTimeout(() => {
                setDisableWebCamIcon(false)
            }, 4000)
        }
    }

    const endMeet = () => {

        if (!peersCount) {
            endRoom(roomId, peersCount);
        }
        const redirectTo = cookiesManager.getItem("sessionToken") ? process.env?.REACT_APP_END_REDIRECTION ?  "/leads" : "/" : process.env?.REACT_APP_END_ROOM_URL === "active" ? "/end_meet_up112" : "/end_meet";
        window.location.href = createRedirectPath(redirectTo);
    }

    useEffect(() => {
        if (micState === "on") {
            clearInterval(intervalId);
            setIntervalId("")

        } else if (!intervalId && micState === "off") {
            const handleAudioData = (dataArray) => {
                const normalizedArray = Array.from(dataArray, (value) => value / 255);
                const isSpeakingCheck = normalizedArray.some((value) => value > 0.5) && micState !== 'on';
                setIsSpeaking(isSpeakingCheck)
            };

            navigator.mediaDevices.getUserMedia({
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                    googEchoCancellation: true,
                    googAutoGainControl: true,
                    googNoiseSuppression: true,
                    googHighpassFilter: true,
                    googTypingNoiseDetection: true,
                    googNoiseReduction: true,
                    // volume: 1.0,
                }
            }).then((stream) => {
                const audioContext = new AudioContext();
                const analyserNode = audioContext.createAnalyser();
                const sourceNode = audioContext.createMediaStreamSource(stream);

                sourceNode.connect(analyserNode);
                analyserNode.connect(audioContext.destination);
                analyserNode.disconnect(audioContext.destination);

                const dataArray = new Uint8Array(analyserNode.frequencyBinCount);

                const intervalId = setInterval(() => {
                    analyserNode.getByteTimeDomainData(dataArray);
                    handleAudioData(dataArray);
                    setTimeout(() => {
                        setIsSpeaking(false)
                    }, removeMicOnWarningTime);
                }, showMicOnWarningTime);
                setIntervalId(intervalId)

                return () => {
                    clearInterval(intervalId);
                    audioContext.close();
                    stream.getTracks().forEach((track) => track.stop());
                };
            }).catch((error) => console.log("footer.jsx useEffect getUserMedia", error));
        }
        // eslint-disable-next-line
    }, [micState]);

    useEffect(() => {
        if (webcamState === 'on') return setDisableWebCamIcon(false)
    }, [webcamState])

    // useEffect(() => {
    //     scrollInto.current.scrollIntoView()
    //   })

    return (
        <>
            <Box className='MeetingMenuBar' sx={{
                p: '14px',
                zIndex: 9999
            }} /* ref={scrollInto} */>
                <ListItemIcon sx={{
                    display: 'flex',
                    justifyContent: roomClient?._role === 'produce' || promoteToHost ? 'space-between' : 'center',
                    alignItems: 'center',
                }}>
                    {(roomClient?._role === 'produce' || promoteToHost) &&
                        <>
                            <CustomWidthTooltip
                                TransitionComponent={Zoom}
                                title={
                                    <>
                                        <CloseIcon sx={{ float: 'right', width: '3vh', height: '3vh', p: '4px' }} onClick={() => setIsSpeaking(false)}></CloseIcon>
                                        <Typography sx={{ fontSize: '12px', p: '5px' }}>Are you talking? Your mic is off. Click the mic to turn it on.</Typography>
                                    </>
                                }
                                arrow placement={"bottom"}
                                open={isSpeaking}
                            >
                                <LoadingButton
                                    loading={null}
                                    loadingPosition="center"
                                    sx={{
                                        ...btnSx,
                                        background: !micBlock || !micAndCamPermissions?.mic ? '#8b0000' : (micState === 'on' ? '#1770F6' : '#222222'),
                                        "&:hover": {
                                            background: '#222222',
                                        },
                                        gap: '10px',
                                        padding: '0 15px 0 12px',
                                        height: '42px',
                                        width: 'auto',
                                        borderRadius: '100px',
                                        opacity: !micBlock || !micAndCamPermissions?.mic ? 0.6 : (roomState === "connected" ? 1 : 0.6)
                                    }}
                                    onClick={() => roomState === "connected" ? handleEnableMic() : ""}
                                    endIcon={
                                        <MenuIcon
                                            sx={{ width: '16px', height: '16px' }}
                                            aria-label="add"
                                            onClick={(e) => handleClickVideoDevices(e, "Microphones", "audio")}
                                            aria-controls={Boolean(anchorElDevices) ? 'video-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(anchorElDevices) ? 'true' : undefined}
                                        />
                                    }
                                >
                                    {micState === 'on' ? <KeyboardVoiceIcon /* onClick={() => roomState === "connected" ? handleEnableMic() : ""} */ />
                                        : <MicOffIcon /* onClick={() => roomState === "connected" ? handleEnableMic() : ""}  */ />}

                                </LoadingButton>
                            </CustomWidthTooltip>

                            <LoadingButton
                                loading={disableWebCamIcon ? true : null}
                                loadingPosition="center"
                                sx={{
                                    ...btnSx,
                                    background: !cameraBlock || !micAndCamPermissions?.camera ? '#8b0000' : (webcamState === 'on' ? '#1770F6' : disableWebCamIcon ? '#fff' : '#222222'),
                                    "&:hover": {
                                        background: disableWebCamIcon ? '#fff' : '#222222',
                                    },
                                    gap: '10px',
                                    padding: '0 15px 0 12px',
                                    height: '42px',
                                    width: 'auto',
                                    borderRadius: '100px',
                                    opacity: !cameraBlock || !micAndCamPermissions?.camera ? 0.6 : (roomState === "connected" ? 1 : 0.6)
                                }}
                            >
                                {webcamState === 'on' ? <VideocamIcon onClick={() => roomState === "connected" ? handleEnableCam() : ""} />
                                    : <VideocamOffIcon onClick={() => roomState === "connected" ? handleEnableCam() : ""} />}
                                <MenuIcon
                                    sx={{ width: '16px', height: '16px' }}
                                    aria-label="add"
                                    onClick={(e) => handleClickVideoDevices(e, "Camera", "video")}
                                    aria-controls={Boolean(anchorElDevices) ? 'video-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={Boolean(anchorElDevices) ? 'true' : undefined}
                                />
                            </LoadingButton>

                            {!promoteToHost && <>
                                <Button sx={{
                                    ...btnSx,
                                    background: participantsTabStatus ? '#1770F6' : '#222222',
                                    "&:hover": {
                                        background: participantsTabStatus ? '#1770F6' : '#222222',
                                    }

                                }} onClick={() => openParticipantsTab(participantsTabStatus ? false : true)}><PeopleAltIcon /></Button>
                                <Button sx={{
                                    ...btnSx,
                                    background: chatTabStatus ? '#1770F6' : '#222222',
                                    "&:hover": {
                                        background: chatTabStatus ? '#1770F6' : '#222222',
                                    }
                                }} onClick={() => openChatTab(chatTabStatus ? false : true)}>
                                    <ChatBubbleIcon />
                                    {isNewMessage && <div className="image-indicators">
                                        <label htmlFor="point-1" id="label-1" >
                                            <span>
                                                <span></span>
                                            </span>
                                        </label>
                                    </div>}
                                </Button>
                            </>}

                        </>}

                    {roomClient?._role === 'produce' && meetType !== 'meet' ?

                        <Button sx={{
                            ...btnSx,
                            background: '#d32f2f',
                            "&:hover": {
                                background: '#d32f2f',
                            }
                        }}
                            onClick={(event) => handleClickEnd(Boolean(anchorElEnd) ? false : event)}
                            aria-label="add"
                            aria-controls={Boolean(anchorElEnd) ? 'end-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorElEnd) ? 'true' : undefined}
                        >
                            <CallEndIcon />
                        </Button>
                        :
                        <Button sx={{
                            ...btnSx,
                            background: '#d32f2f',
                            "&:hover": {
                                background: '#d32f2f',
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                            onClick={() => endMeet()}
                        // onClick={() => meetType !== 'meet' ? setOpenLeave(true) : window.location.href = "/"}
                        >
                            <CallEndIcon />
                        </Button>
                    }

                </ListItemIcon>
            </Box>

            {/* Show pop up on media on off by host */}
            <ManageAllExternelCases
                roomClient={roomClient}
                handleEnableMic={handleEnableMic}
                handleEnableCam={handleEnableCam}
            />


            {/* Menu for end webinar button. */}
            <EndWebinarIconMenu
                anchorElEnd={anchorElEnd}
                handleCloseEnd={handleCloseEnd}
                setOpenEndAll={() => setOpenEndAll(true)}
                setOpenLeave={() => setOpenLeave(true)}
            />

            {/* Leave webinar modal */}
            <LeaveWebinarModal
                openLeave={openLeave}
                setOpenLeave={() => setOpenLeave(false)}
            />

            {/* End webinar modal */}
            <EndWebinarModal
                openEndAll={openEndAll}
                setOpenEndAll={() => setOpenEndAll(false)}
                endSuccessLoading={endSuccessLoading}
                endWebinar={endWebinar}
            />

            <DevicesMenu
                anchorElDevices={anchorElDevices}
                handleCloseDevices={() => setAnchorElDevices(false)}
                type={deviceMenu?.type}
                title={deviceMenu?.title}
                roomClient={roomClient}
            />

            {/* Show steps on permission block. */}
            <MicAndVideoPermissionBlock
                open={permissionBlock}
                close={() => setPermissionBlock(false)}
            />
        </>
    )
}

export default memo(FooterWebinar);