import React from 'react';
import { Typography, useTheme } from '@mui/material';

const Paragraph = ({ text = "", highlightWords = [] }) => {
  const theme = useTheme()
  return (
    <Typography
      variant='body1'
      component="p"
      sx={{ color: 'grey.600' }}
    >
      {text.split(' ').map((word, index) => (
        <span key={index} style={highlightWords.includes(word) ? { color: theme.palette.grey[900] } : {}}>
          {word}{' '}
        </span>
      ))}
    </Typography>
  )
}

export default Paragraph;

