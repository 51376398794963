import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
// import PropTypes from 'prop-types';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = ({ setActive }) => {
    const [value, setValue] = React.useState(0);
    const statusMapper = {
        0: "all",
        1: "upcoming",
        2: "previous",
        3: "quickmeetings"
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setActive(statusMapper[newValue])
    };

    const allLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: 700,
                color: value === 0 ? 'grey.70' : 'grey.700',
            }}>
                All
            </Typography>
        </Box>
    )

    const upcomingLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: 700,
                color: value === 1 ? 'grey.70' : 'grey.700',
            }}>
                Upcoming
            </Typography>
        </Box>
    )

    const previousLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: 700,
                color: value === 2 ? 'grey.70' : 'grey.700',
            }}>
                Previous
            </Typography>
        </Box>
    )
    const quickLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: 700,
                color: value === 3 ? 'grey.70' : 'grey.700',
            }}>
                Quick Meetings
            </Typography>
        </Box>
    )

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: 'card.main',
            borderRadius: "12px",
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{
                    style: {
                        backgroundColor: "card.main"
                    }
                }} >
                    <Tab label={allLabel} {...a11yProps(0)} />
                    <Tab label={upcomingLabel} {...a11yProps(1)} />
                    <Tab label={previousLabel} {...a11yProps(2)} />
                    <Tab label={quickLabel} {...a11yProps(2)} />
                </Tabs>
            </Box>
            {/* <CustomTabPanelStyle value={value} index={0}>
                Item One
            </CustomTabPanelStyle>
            <CustomTabPanelStyle value={value} index={1}>
                Item Two
            </CustomTabPanelStyle>
            <CustomTabPanelStyle value={value} index={2}>
                Item Three
            </CustomTabPanelStyle> */}
        </Box>
    )
}
export default CustomTabPanel;
