// import React, { useState, useEffect } from 'react';
// import { AppBar, Toolbar, Button, Box, Avatar, MenuItem, Menu, ListItemIcon, Divider } from "@mui/material";
// import { useLocation, useNavigate } from 'react-router-dom';
// import jwt from 'jwt-decode';

// //Icons
// import HomeIcon from '@mui/icons-material/Home';
// import DescriptionIcon from '@mui/icons-material/Description';
// import HelpCenterIcon from '@mui/icons-material/HelpCenter';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import Logout from '@mui/icons-material/Logout';
// import EditIcon from '@mui/icons-material/Edit';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
// // import FeedbackIcon from '@mui/icons-material/Feedback';
// import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';

// // import { configFile } from '../../config';
// import { getItem, setItem, removeBulkItems } from '../../lib/cookiesManager';
// import { getConosleEnv } from "../../config/Enums";
// import MeetLogoSvg from '../../assets/svgs/webinarLogo';

// export default function Navbar() {
//     // const { logoUrl } = configFile;
//     const location = useLocation();
//     const navigate = useNavigate();
//     const [anchorEl, setAnchorEl] = useState(null)
//     const [profileUrl, setProfileUrl] = useState(null);
//     const [showProfile, setShowProfile] = useState(false);
//     const [contactSetting, setContactSetting] = useState(false);
//     const openProfile = Boolean(anchorEl);
//     const consoleEnv = getConosleEnv();

//     const logout = () => {
//         removeBulkItems(["sessionToken", 'exp', 'name', 'token'])
//         navigate('/login');
//     }

//     const fetchUserInfo = async () => {
//         try {
//             let data = getItem("token")
//             let info = jwt(data);
//             setItem("name", info?.name)
//             setProfileUrl(info?.picture)
//             setContactSetting(info?.meet?.contactSetting);
//         } catch (error) {
//             // renderErrorMessage(error.message)
//         }
//     }

//     useEffect(() => {
//         if (!consoleEnv.includes(process.env.REACT_APP_ENV)) setShowProfile(true);
//         fetchUserInfo()
//         // eslint-disable-next-line
//     }, [])

//     const onIconClick = (pathname) => navigate(pathname);

//     const homeSideBar = [{
//         title: 'Home',
//         icon: <HomeIcon />,
//         active: location?.pathname === '/' ? true : false,
//         path: "/",
//         disabled: false,
//     },
//     {
//         title: 'Meeting',
//         icon: <VideoCameraFrontIcon />,
//         active: location?.pathname === '/list' || location?.pathname === '/schedule_webinar' ? true : false,
//         path: "/list",
//         disabled: false,
//     },
//     {
//         title: 'Report',
//         icon: <DescriptionIcon />,
//         active: location?.pathname === '/reports' ? true : false,
//         path: "/reports",
//         disabled: false,
//     },
//     {
//         title: 'Call Center',
//         icon: <AccountTreeIcon />,
//         active: location?.pathname === '/leads' ? true : false,
//         path: "/leads",
//         disabled: false,
//     },

//     {
//         title: 'Help',
//         icon: <HelpCenterIcon />,
//         active: location?.pathname === '/help' ? true : false,
//         path: "/help",
//         disabled: false,
//     },
//     /* {
//         title: 'Feedback',
//         icon: <FeedbackIcon />,
//         active: location?.pathname === '/feedback' ? true : false,
//         path: "/feedback",
//         disabled: false,
//     }, */]


//     return (
//         <>
//             <Box sx={{
//                 flexDirection: 'flex-end',
//                 position: 'fixed',
//                 width: '100%',
//                 color: '#fff',
//                 zIndex: 2,
//                 boxShadow: '68px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
//             }}>
//                 <AppBar position="static" component="nav" sx={{
//                     backgroundColor: 'card.main',
//                     boxShadow: 'none'
//                 }}>
//                     <Toolbar sx={{ marginLeft: '-7px' }}>
//                         <MeetLogoSvg />

//                         <Box sx={{
//                             display: 'flex',
//                             flexDirection: 'row',
//                             justifyContent: 'flex-end',
//                             width: '100%',
//                             gap: '20px',
//                             pr: '20px',
//                             alignItems: 'center'
//                         }}>
//                             {homeSideBar.filter(({ title }) => title !== "Call Center" || contactSetting).map(({ title, icon, active, path }) => (
//                                 <Button key={title} onClick={() => onIconClick(path)} sx={{
//                                     color: active ? "grey.70" : "grey.600",
//                                     height: '34px',
//                                     "&:hover": {
//                                         color: active ? "grey.70" : "grey.600",
//                                         bgcolor: "transparent",
//                                     }
//                                 }} startIcon={icon}>
//                                     {title}
//                                 </Button>
//                             ))}

//                             {showProfile && <Box
//                                 onClick={(e) => setAnchorEl(e.currentTarget)}
//                                 sx={{
//                                     justifyContent: 'center',
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     pl: '30px'
//                                 }}>
//                                 <Avatar variant="square"
//                                     src={profileUrl ? profileUrl : "../../images/avatar.png"}
//                                     sx={{
//                                         bgcolor: '#F3F3F3',
//                                         borderRadius: '10px',
//                                         alignItems: 'center',
//                                         position: 'absolute',
//                                         color: '#454545',
//                                     }}
//                                 />
//                             </Box>}

//                         </Box>
//                     </Toolbar>
//                 </AppBar>
//             </Box>

//             <Menu
//                 anchorEl={anchorEl}
//                 id="account-menu"
//                 open={openProfile}
//                 onClose={() => setAnchorEl(null)}
//                 onClick={() => setAnchorEl(null)}
//                 PaperProps={{
//                     elevation: 0,
//                     sx: {
//                         overflow: 'visible',
//                         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
//                         mt: 3.9,
//                         ml: 3,
//                         '& .MuiAvatar-root': {
//                             width: 32,
//                             height: 32,
//                         },
//                         '&:before': {
//                             content: '""',
//                             display: 'block',
//                             position: 'absolute',
//                             top: 0,
//                             right: 20,
//                             width: 10,
//                             height: 10,
//                             bgcolor: 'background.paper',
//                             transform: 'translateY(-50%) rotate(45deg)',
//                             zIndex: 0,
//                         },
//                     },
//                 }}
//                 transformOrigin={{ horizontal: 'right', vertical: 'top' }}
//                 anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
//             >
//                 <MenuItem onClick={() => navigate('/profile')}>
//                     <ListItemIcon>
//                         <AccountBoxIcon />
//                     </ListItemIcon>
//                     Profile
//                 </MenuItem>
//                 <MenuItem disabled>
//                     <ListItemIcon>
//                         <EditIcon />
//                     </ListItemIcon>
//                     Edit Profile
//                 </MenuItem>

//                 <Divider />

//                 <MenuItem onClick={(e) => logout()}>
//                     <ListItemIcon>
//                         <Logout fontSize="small" />
//                     </ListItemIcon>
//                     Logout
//                 </MenuItem>
//             </Menu>

//         </>

//     )
// }

import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Button,
    Box,
    Badge,
    SvgIcon,
    Popover,
    ListItemButton,
    List,
    ListItemText,
    Typography,
    ListItemIcon,
    Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import Notification3LineIcon from '../../assets/svgs/icons/notification-3-line';
import Settings5LineIcon from '../../assets/svgs/icons/settings-5-line';
import MoonLineIcon from '../../assets/svgs/icons/moon-line';
import { ColorModeContext } from "../../context/ThemeContext";
import ArrowDownSFillIcon from "../../assets/svgs/icons/fill/arrow-down-s-fill";
import UserLineIcon from "../../assets/svgs/icons/user-line";
import NewsLineIcon from "../../assets/svgs/icons/news-line";
import LogoutCircleLineIcon from "../../assets/svgs/icons/logout-circle-line";
import MeetLogoSvg from '../../assets/svgs/webinarLogo';
import jwt from 'jwt-decode';
import { getItem, setItem, } from '../../lib/cookiesManager';


const LogoSection = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "10px",
    width: "180px",
    height: "50px",
    marginLeft: "-38px",
    [theme.breakpoints.down("sm")]: {
        marginLeft: "20px",
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export default function Navbar() {
    const [contactSetting, setContactSetting] = useState(false);
    const navigate = useNavigate();
    const colorMode = useContext(ColorModeContext)

    const [anchorElProfile, setAnchorElProfile] = React.useState(null);

    const handleClickProfile = (event) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleCloseProfile = () => {
        setAnchorElProfile(null);
    };

    const openProfile = Boolean(anchorElProfile);
    const idProfile = openProfile ? 'simple-popover-profile' : undefined;

    const fetchUserInfo = async () => {
        try {
            let data = getItem("token")
            let info = jwt(data);
            // setItem("name", info?.name)
            // setProfileUrl(info?.picture)
            setContactSetting(info?.meet?.contactSetting);
        } catch (error) {
        }
    }

    useEffect(() => {
        fetchUserInfo()
    }, [])
    const handleThemeChange = () => {
        try {
            const currentTheme = getItem('theme') || 'light';
            const newTheme = currentTheme === 'light' ? 'dark' : 'light';

            setItem("theme", newTheme);

            window.location.reload()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box
            sx={{
                pl: '60px',
                flexDirection: "flex-end",
                position: "fixed",
                width: "100%",
                // color: "#fff",
                zIndex: 3,
                // boxShadow: "59px 0.5px 3px 0px rgba(117, 117, 117, 0.10)",
                height: '50px',
                // backgroundColor: "grey.0",
            }}
        >
            <AppBar
                position="static"
                component="nav"
                sx={{
                    // backgroundColor: "grey.0",
                    boxShadow: "none",
                    // alignItems: 'center',
                    height: '50px',
                    minHeight: '50px !important',

                }}
            >
                <Toolbar sx={{
                    minHeight: '50px !important',
                    backgroundColor: "grey.0",
                    borderBottom: '1px solid',
                    borderColor: 'grey.300',
                }}>
                    <LogoSection>
                        <MeetLogoSvg width="auto" height={"28px"} />
                    </LogoSection>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                            gap: "20px",
                        }}
                    >
                        <Button
                            onClick={() => navigate('/')}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Home
                        </Button>
                        <Button
                            onClick={() => navigate('/list')}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Meeting
                        </Button>
                        <Button
                            onClick={() => navigate('/reports')}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Report
                        </Button>
                        {contactSetting && <Button
                            onClick={() => navigate('/leads')}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Call Center
                        </Button>}
                        <Button
                            onClick={() => navigate('/help')}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            Help
                        </Button>

                        {/* <Button
                            // onClick={() => handleNavigate("/settings")}
                            key={"settings"}
                            variant='outlined'
                            // color="secondary"
                            sx={{
                                color: location.pathname === '/settings' ? 'grey.700' : 'grey.700',
                                height: '34px',
                                fontSize: '12px',
                                fontWeight: 600,
                                border: '1px solid',
                                borderColor: 'grey.700',
                                "&:hover": {
                                    color: 'grey.700',
                                    bgcolor: "transparent",
                                    borderColor: 'grey.700',
                                }
                            }}
                            startIcon={<SvgIcon sx={{
                                color: 'grey.700',
                                width: '16px',
                                height: '16px',
                            }}>
                                <HeadphoneLineIcon color={"currentColor"} />
                            </SvgIcon>}
                        >
                            Help Center
                        </Button> */}

                        <StyledBadge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            variant="dot"
                        >
                            <IconButton sx={{ color: 'grey.700' }}
                            //  onClick={() => handleNavigate("/settings")}
                            >
                                <SvgIcon sx={{
                                    color: 'grey.700',
                                    width: '16px',
                                    height: '16px',
                                }}>
                                    <Notification3LineIcon color={"currentColor"} />
                                </SvgIcon>
                            </IconButton>
                        </StyledBadge>

                        <IconButton sx={{ color: 'grey.700' }}
                            onClick={() => window.location.href = "/settings"}
                        >
                            <SvgIcon sx={{
                                color: 'grey.700',
                                width: '16px',
                                height: '16px',
                            }}>
                                <Settings5LineIcon color={"currentColor"} />
                            </SvgIcon>
                        </IconButton>
                        <IconButton sx={{ color: 'grey.700' }}
                            onClick={() => handleThemeChange()}
                        >
                            <SvgIcon sx={{
                                color: 'grey.700',
                                width: '16px',
                                height: '16px',
                            }}>
                                <MoonLineIcon color={"currentColor"} />
                            </SvgIcon>
                        </IconButton>

                        <Button
                            aria-describedby={idProfile}
                            onClick={handleClickProfile}
                            sx={{
                                color: 'grey.600',
                                fontSize: '14px',
                                fontWeight: 500,
                                padding: '0px',
                                "&:hover": {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            endIcon={<SvgIcon>
                                <ArrowDownSFillIcon color={"currentColor"} />
                            </SvgIcon>}
                        >
                            <Avatar src="./test" sx={{ height: '24px', width: '24px' }} />
                        </Button>

                        <Popover
                            id={idProfile}
                            open={openProfile}
                            anchorEl={anchorElProfile}
                            onClose={handleCloseProfile}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <List sx={{
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                border: '1px solid',
                                borderColor: 'grey.300',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                backgroundColor: 'card.main'
                            }}>
                                <ListItemButton
                                    sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
                                    onClick={() => window.location.href = '/settings'}
                                >
                                    <ListItemIcon sx={{ minWidth: "30px" }}>
                                        <SvgIcon sx={{
                                            width: '18px',
                                            height: '18px'
                                        }}>
                                            <UserLineIcon color={'currentColor'} />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'grey.600' }}>
                                            My Account
                                        </Typography>}
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
                                    onClick={() => window.location.href = '/settings?tab=1'}
                                >
                                    <ListItemIcon sx={{ minWidth: "30px" }}>
                                        <SvgIcon sx={{
                                            width: '18px',
                                            height: '18px'
                                        }}>
                                            <NewsLineIcon color={'currentColor'} />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'grey.600' }}>
                                            KYC
                                        </Typography>}
                                    />
                                </ListItemButton>

                                <ListItemButton
                                 sx={{ borderBottom: '1px solid', borderColor: 'grey.300' }}
                                 onClick={() => window.location.href = '/logout'}
                             >
                                
                                    <ListItemIcon sx={{ minWidth: "30px" }} >
                                        <SvgIcon sx={{
                                            width: '18px',
                                            height: '18px'
                                        }}>
                                            <LogoutCircleLineIcon color={'currentColor'} />
                                        </SvgIcon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography sx={{ fontSize: '14px', fontWeight: 500, color: 'grey.600' }}>
                                            Logout
                                        </Typography>}
                                    />
                                </ListItemButton>
                            </List>
                        </Popover>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box >
    );
}

