import React from 'react';
import { useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

// const env = process.env.REACT_APP_PRODUCT_ENV;
// let Logo = await import(`./${env}/logo`);
// Logo = Logo.default

// const MeetLogoSvg = ({ width = "90px", height = "53px" }) => <Logo width={width} height={height} />

const MeetLogoSvg = ({ width = "auto", height = "45px" }) => {
    const { branding = {} } = useSelector(state => state?.branding);
    const { palette: { mode = "light" } } = useTheme();
    const { meetLogoUrlLight = "", meetLogoUrlDark = "" ,logoUrlDark ="",logoUrlLight =""} = branding || {};
    return (
        <img src={mode === "light" ?  meetLogoUrlLight ||logoUrlLight : meetLogoUrlDark||logoUrlDark} width={width} height={height} alt="Logo" />
    )
}
export default MeetLogoSvg;
