import React from 'react'
import { checkIframe } from '../lib/cookiesManager'
import { configFile } from '../config'
import { Box } from '@mui/material';

const { consoleUrl } = configFile;

const Spinner = () => {
    return (
        <Box sx={{
            position: 'fixed',
            bottom: '0px',
            left: 0,
            width: "60px",
            height: '100vh',
            overflow: 'hidden',
            margin: '0px',
            padding: '0px'
        }}>
            <iframe
                title='console'
                src={!checkIframe() ? `${consoleUrl}/spinner?k=meet&s=true&spinner=${true}` : `${consoleUrl}/spinner?k=meet&s=true`}
                // allow="camera *;microphone *"
                style={{
                    top: " 0",
                    left: "0",
                    bottom: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                    border: '0px'
                }}
            />

        </Box>
    )
}

export default Spinner