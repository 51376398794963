import React from 'react';
import { Box, Typography, useMediaQuery ,} from '@mui/material';
import Heading from './components/heading';
import Pragraph from './components/pragraph';
import ListComp from './components/list';
import ImageComp from './components/imageComp';
import { capitalizeFirstLetter } from './components/util';
import { useSelector } from 'react-redux';

const GetStarted = () => {
    const baseurl = window.location.origin
    const isMobile = useMediaQuery('(max-width:600px)');
    const { branding = {} } = useSelector(state => state?.branding);
    const { companyBrandName = "" } = branding || {};
    const capitalizedCompanyBrandName = capitalizeFirstLetter(companyBrandName);

    return (
        <>
            <Heading
                text={`Get Started With ${capitalizedCompanyBrandName}`}
            />
       <ImageComp
    name={companyBrandName === 'safeconnect' ? "welcomesafeconnect" : "welcome"}
/>


            <Box sx={{ width: isMobile ? '100%' : '80%' }}>
            <Pragraph
                    text={`${capitalizedCompanyBrandName}: Encrypted Made in India Video Conferencing Platform`}
                    highlightWords={[`${capitalizedCompanyBrandName}:`, 'Made', 'India', 'in']}
                />
            </Box>

            <Box sx={{ width: isMobile ? '100%' : '80%' }}>
                <Pragraph
                    text={`${capitalizedCompanyBrandName} an Indian-developed video conferencing platform, prioritizes secure meetings with a high capacity for participants (up to 1000). It functions well even with limited bandwidth, making it suitable for areas with slower internet connections. The platform promotes data-saving features for extended video conferences.`}
                />

            </Box>
            <Box sx={{ width: isMobile ? '100%' : '80%' }}>

                <Pragraph
                    text={`${capitalizedCompanyBrandName} allows you to host video meetings in an instant, conduct interviews, schedule training classes, and much more. The possibilities depend on your imagination.`}
                />
            </Box>

            <Box sx={{ width: isMobile ? '100%' : '80%' }}>

                <Pragraph
                    text='This guide will help you join or schedule your meetings, learn more about the tool, its unique or common features, and more.

    '
                />
            </Box>

            <ListComp
                data={[{
                    name: 'Pin people to focus on the screen.',
                    id: '12',

                }, {
                    name: 'End-to-end encryption to ensure security.',
                    id: '13'
                }, {
                    name: 'Schedule meetings flexibly.',
                    id: '14'
                }, {
                    name: 'Instant meeting with Quick Meeting Feature.',
                    id: '15'
                }, {
                    name: 'Block and unblock participant',
                    id: '16'
                },
                ]}
            />

            <ListComp
                headingText='What you need to get started:'
                data={[{
                    name: '5 minutes',
                    id: '17',

                },                     { name: `${capitalizedCompanyBrandName} account`, id: '18' },
                {
                    name: <Typography>
                        *If you don’t have one , <a href={`${baseurl}/`} style={{ color: 'grey.400', textDecoration: 'none' }}>
                            Create One Here
                        </a>
                    </Typography>,
                    id: '19'
                },
                ]}
            />

        </>
    );
};

export default GetStarted;


