import React, { useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { configFile } from '../../../config';
// import MeetLogoSvg from '../../../assets/svgs/webinarLogo';

export default function MobileEndWebianr() {
    const { imagesUrl } = configFile;

    useEffect(() => {
        window.location.hash = "no-back-button";

        // Again because Google Chrome doesn't insert
        // the first hash into the history
        window.location.hash = "Again-No-back-button";

        window.onhashchange = function () {
            window.location.hash = "no-back-button";
        }
    }, [])

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            overflow: "hidden"
        }}>
            <Box sx={{
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: '10px',
                marginTop: '-75px',
                gap: '15px'
            }}>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={imagesUrl?.up112Logo} alt="Logo" style={{ width: '150px', height: 'auto' }} />
                </Box>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    // mb: 2,
                    mt: 4,
                    color: "grey.70"
                }}>
                   Solution By
                </Typography>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={imagesUrl?.bellLogo} alt="Logo" style={{ width: '200px', height: 'auto' }} />
                </Box>
                {/* <MeetLogoSvg width='120px' /> */}

                <Typography sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: "grey.400",
                    mt: 4,
                }}>
                    In Association with
                </Typography>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={imagesUrl?.sarvLogo} alt="Logo" style={{ width: '150px', height: 'auto' }} />
                </Box>
            </Box>

        </Grid>
    )
}
