import * as React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { TableCell as ActualTableCell, styled, useTheme, Popover } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Icon } from '@iconify/react';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { useNavigate } from 'react-router-dom';
import randomString from 'random-string';
import { wsEmit } from '../../../lib/socketIo';
import { LeadsContext } from '../../../context/leadsContext';
import { SOCKET_ENVENTS } from '../../../config/Enums';
import { generateFingerprint, getMicAndCamPermission } from '../../../utils/common';
import { configFile } from '../../../config';
import moment from 'moment'

const { getProtooUrl } = configFile;

const TableCell = styled(ActualTableCell)(({ theme }) => ({
    color: theme.palette.grey[600],
    // backgroundColor: theme.palette.grey[1000]
}))

function Row(props) {
    const { row, fetchList, value } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme()
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate()

    const { socket, name } = React.useContext(LeadsContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const openPopover = Boolean(anchorEl);
      const id = openPopover ? 'simple-popover' : undefined;

    function copyToClip(roomId) {
        const text = `https://${window.location.hostname}/join_meet?roomId=${roomId}`

        navigator.clipboard.writeText(text);

        renderSuccessMessage("Link copid!")
    }

    const handleJunk = async (callId) => {
        try {
            const { message } = await wsEmit(socket, SOCKET_ENVENTS?.MARK_AS_JUNK, { callId });

            renderSuccessMessage(message)

            fetchList(socket, value)

        } catch (error) {
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const handleConnect = async (callId) => {
        try {
            const response = await wsEmit(socket, SOCKET_ENVENTS?.ACCEPT_CALL, { callId });

            const { title = "", description = "", isHostServer, record, autoRecord,
                disableRecordPlayPause, startDate, endDate, waitingRoom, e2ee,
                serverDetails, isWaitingRoom,
                roomId
            } = response;

            const role = "produce";
            const peerId = randomString({ length: 8 }).toLowerCase();
            // const name = randomString({ length: 6 }).toLowerCase();
            const fp = await generateFingerprint();

            const wssUrl = getProtooUrl({ roomId, peerId, serverDetails })

            getMicAndCamPermission();

            const redirectURL = `/meet?roomId=${roomId}&peerId=${peerId}`;
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=${fp}` || null;

            const state = {
                protooUrl, peerId, role, name, title, description, meetType: "meet", record, autoRecord,
                disableRecordPlayPause, startDate, endDate, roomId, waitingRoom, e2ee, isWaitingRoom
            };

            navigate(redirectURL, { state });

        } catch (error) {
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const formateTime = (ts) => {
        const dateTime = moment(ts).format(" hh:mm A, DD-MM-YYYY");
        return dateTime;
    }

    const renderStatus = (status) => {
        const colorMapper = {
            running: { color: 'primary.main', text: "Running" },
            rejected: { color: 'error.main', text: "Junk" },
            pending: { color: 'purple.main', text: "Waiting" },
            completed: { color: 'success.main', text: "Completed" },
            disconnected: { color: 'error.main', text: "Disconnected" }
        }

        const { color = "", text = "" } = colorMapper?.[status] || {};

        return (
            <Typography sx={{ color, typography: 'body2' }}>
                {text}
            </Typography>
        )
    }

    function getDuration(startDate, endDate) {
        if (!(startDate && endDate)) return "--"
        const start = new Date(startDate);
        const end = new Date(endDate);

        const differenceInMs = end - start;

        let seconds = Math.floor(differenceInMs / 1000);

        // Calculate minutes, hours, and days
        const days = Math.floor(seconds / 86400); // 86400 seconds in a day
        seconds %= 86400;

        const hours = Math.floor(seconds / 3600); // 3600 seconds in an hour
        seconds %= 3600;

        const minutes = Math.floor(seconds / 60); // 60 seconds in a minute
        seconds %= 60;

        // Construct the duration string
        let duration = '';
        if (days > 0) {
            duration += `${days} day${days !== 1 ? 's' : ''} `;
        }
        if (hours > 0) {
            duration += `${hours} hour${hours !== 1 ? 's' : ''} `;
        }
        if (minutes > 0) {
            duration += `${minutes} minute${minutes !== 1 ? 's' : ''} `;
        }
        if (seconds > 0 || duration === '') { // Include seconds even if zero, if no other units
            duration += `${seconds} second${seconds !== 1 ? 's' : ''} `;
        }

        return duration.trim() /* + ' ago' */;
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row?.roomId || "--"}
                </TableCell>
                <TableCell align="center">{row?.id || "--"}</TableCell>
                <TableCell align="center">{row?.status ? renderStatus(row?.status) : "--"}</TableCell>
                <TableCell align="center">{formateTime(row?.createdAt) || "--"}</TableCell>
                <TableCell align="center">{row?.roomStartTime ? formateTime(row?.roomStartTime) : "--"}</TableCell>
                <TableCell align="center">{row?.lastUpdatedAt ? formateTime(row?.lastUpdatedAt) : "--"}</TableCell>
                <TableCell align="center">{getDuration(row?.roomStartTime, row?.lastUpdatedAt)}</TableCell>
                <TableCell align="center">{row?.agentName || "--"}</TableCell>
                <TableCell align="center">{row?.remark || "--"}</TableCell>
                <TableCell align="center">
                    {/* <Tooltip title={"More Details"}> */}
                    <Icon
                        icon={"ph:info"}
                        style={{ color: theme.palette.primary.main, width: '20px', height: '20px' }}
                        // onClick={() => handleConnect(row?.id)}
                        aria-describedby={id} onClick={handleClick}
                    />
                    <Popover
                        id={id}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '14px' }}>
                            <Typography variant='body1' sx={{ color: 'grey.600' }}>Name: {row?.callerName || "--"}</Typography>
                            <Typography variant='body1' sx={{ color: 'grey.600' }}>Mobile: {row?.callerMobile || "--"}</Typography>
                            <Typography variant='body1' sx={{ color: 'grey.600' }}>IP: {row?.callerIp || "--"}</Typography>
                            <Typography variant='body1' sx={{ color: 'grey.600' }}>FP: {row?.fingerprint || "--"}</Typography>
                        </Box>
                    </Popover>
                    {/* </Tooltip> */}
                </TableCell>
                <TableCell align="center">
                    {row?.status === "pending" ? <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Tooltip title={"Connect"}>
                            <Icon
                                icon={"fluent-mdl2:join-online-meeting"}
                                style={{ color: theme.palette.success.main }}
                                onClick={() => handleConnect(row?.id)}
                            />
                        </Tooltip>

                        <Tooltip title={"Mark Junk"}>
                            <Icon
                                icon={"ph:trash-bold"}
                                style={{ color: theme.palette.error.main }}
                                onClick={() => handleJunk(row?.id)}
                            />
                        </Tooltip></Box>
                        : row?.status === "running" ? <Tooltip title={"Copy room link"}>
                            <Icon
                                icon={"tabler:copy"}
                                style={{ color: theme.palette.error.main }}
                                onClick={() => copyToClip(row?.roomId)}
                            />
                        </Tooltip>
                            : "--"}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{color: 'grey.600'}}>
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ backgroundColor: 'grey.300' }} >Name</TableCell>
                                        <TableCell sx={{ backgroundColor: 'grey.300' }} >Join Time</TableCell>
                                        <TableCell sx={{ backgroundColor: 'grey.300' }} align="center">Leave Time</TableCell>
                                        <TableCell sx={{ backgroundColor: 'grey.300' }} align="center">Duration</TableCell>
                                        {/* <TableCell sx={{ backgroundColor: 'grey.1000' }} align="center">Total price ($)</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.history && row.history.map((historyRow) => (
                                        <TableRow key={historyRow?.id}>
                                            <TableCell component="th" scope="row">
                                                {historyRow?.displayName || "--"}
                                            </TableCell>
                                            <TableCell>{historyRow?.joinedAt ? formateTime(historyRow?.joinedAt) : "--"}</TableCell>

                                            <TableCell align="center">{historyRow?.lastUpdatedAt ? formateTime(historyRow?.lastUpdatedAt) : "--"}</TableCell>

                                            <TableCell align="center">
                                                {getDuration(historyRow?.joinedAt, historyRow?.lastUpdatedAt)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            {!row?.history?.length && <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                p: 7,
                                width: '100%'
                            }}>
                                <Typography variant="h6" sx={{ textAlign: 'center', color: 'grey.400' }}>No data found!</Typography>
                            </Box>}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function LeadsTable({ data = [], fetchList, value, listLoading }) {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 425px)' }}>
            <Table stickyHeader aria-label="collapsible table">
                <TableHead >
                    <TableRow>
                        <TableCell sx={{ backgroundColor: 'grey.300' }} key={"row"} />
                        <TableCell sx={{ backgroundColor: 'grey.300' }} key={"rId"}>Room ID</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"cId"}>Call ID</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"status"}>Status</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"cat"}>Created Time</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"st"}>Start Time</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"et"}>End Time</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"duration"}>Duration</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"agent"}>Agent</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"remark"}>Remark</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"details"}>Details</TableCell>
                        <TableCell align="center" sx={{ backgroundColor: 'grey.300' }} key={"Actions"}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, i) => (
                        <Row
                            key={i}
                            row={row}
                            fetchList={fetchList}
                            value={value}
                        />
                    ))}
                </TableBody>


            </Table>
            {!data?.length && !listLoading && <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 7,
                width: '100%'
            }}>
                <Typography variant="h6" sx={{ textAlign: 'center', color: 'grey.500' }}>No data found!</Typography>
            </Box>}

            {listLoading && <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 7,
                width: '100%'
            }}>
                <Typography variant="h6" sx={{ textAlign: 'center', color: 'grey.500' }}>Loading...</Typography>
            </Box>}
        </TableContainer>
    );
}
