import UrlParse from 'url-parse';
import React, { useEffect, useState, useRef } from 'react';
import randomString from 'random-string';
import Logger from '../../../lib/Logger';
import deviceInfo from '../../../lib/deviceInfo';
import RoomClient from '../../../lib/RoomClient';
import RoomContext from '../../../context/RoomContext';
import * as stateActions from '../../../redux/actions/stateActions';
import MobileRoom from '../../../components/mobile/room/Room';
import store from '../../../store';
import { useLocation } from 'react-router-dom';
import { createRedirectPath, refreshCookiesOnWebStart } from '../../../utils/common'
import { sseClose } from '../../../lib/Sse';
import E2eModal from "../../../components/common/room/Modal/E2eModal";
import { toast } from 'react-toastify';

const logger = new Logger();

let roomClient;
RoomClient.init({ store });

export default function MobileWebinar() {
	const location = useLocation();
	const [e2eModal, setE2eModal] = useState(true);
	const deviceWarningShown = useRef(false);
	const e2eeWarningShown = useRef(false);

	useEffect(() => {
		// window.addEventListener('popstate', (event) => {
		// 	if (event.state) {
		// 		logger.debug('Browser back buttom pressed.');
		// 		roomClient.close()
		// 		sseClose()
		// 		window.location.href = createRedirectPath('/');
		// 	}
		// }, false);
		const handlePopState = (event) => {
			if (event.state) {
					console.debug('Browser back button pressed.');
					// Assuming roomClient.close() and sseClose() are defined somewhere
					roomClient.close();
					sseClose();
					window.location.href = createRedirectPath('/');
			}
	};

	window.addEventListener('popstate', handlePopState, false);

		// return () => {
		// 	logger.debug('Webinar() useEffect component crash error handler');
		// 	// window.location.href = '/';
		// }
		return () => {
			window.removeEventListener('popstate', handlePopState);
			console.debug('Webinar() useEffect component cleanup');
	};
	}, []);

	logger.debug('Webinar() [environment:%s]', process.env.NODE_ENV);

	const protooUrl = location?.state?.protooUrl || null;

	const urlParser = new UrlParse(window.location.href, true);
	const peerId = location?.state?.peerId || randomString({ length: 8 }).toLowerCase();
	let roomId = urlParser.query.roomId;
	let displayName = location?.state?.name;
	const handler = urlParser.query.handler;
	const useSimulcast = urlParser.query.simulcast !== 'false';
	const useSharingSimulcast = urlParser.query.sharingSimulcast !== 'false';
	const forceTcp = urlParser.query.forceTcp === 'true';
	const produce = urlParser.query.produce !== 'false';
	const consume = urlParser.query.consume !== 'false';
	const forceH264 = urlParser.query.forceH264 === 'true';
	const forceVP9 = urlParser.query.forceVP9 === 'true';
	const svc = urlParser.query.svc;
	const datachannel = urlParser.query.datachannel !== 'false';
	const info = urlParser.query.info === 'true';
	const faceDetection = urlParser.query.faceDetection === 'true';
	const externalVideo = urlParser.query.externalVideo === 'true';
	const throttleSecret = urlParser.query.throttleSecret;
	const e2ee = location?.state?.e2ee;
	const e2eKey = decodeURIComponent(urlParser.query?.key?.toString() || "") || "";
	// const e2eKey = urlParser.query.e2eKey;
	const role = location?.state?.role || 'consumer';
	// const theme = useTheme();
	if (!protooUrl) return window.location.href = createRedirectPath('/');

	if (info) {
		window.SHOW_INFO = true;
	}

	if (throttleSecret) {
		window.NETWORK_THROTTLE_SECRET = throttleSecret;
	}

	if (!roomId) {
		roomId = randomString({ length: 8 }).toLowerCase();

		urlParser.query.roomId = roomId;
		window.history.pushState('', '', urlParser.toString());
	}

	// Get the effective/shareable Room URL.
	const roomUrlParser = new UrlParse(window.location.href, true);

	for (const key of Object.keys(roomUrlParser.query)) {
		switch (key) {
			case 'roomId':
			case 'handler':
			case 'simulcast':
			case 'sharingSimulcast':
			case 'produce':
			case 'consume':
			case 'forceH264':
			case 'forceVP9':
			case 'forceTcp':
			case 'svc':
			case 'datachannel':
			case 'info':
			case 'faceDetection':
			case 'externalVideo':
			case 'throttleSecret':
				// case 'e2eKey':
				break;

			default:
				delete roomUrlParser.query[key];
		}
	}
	delete roomUrlParser.hash;

	const roomUrl = roomUrlParser.toString();

	let displayNameSet;

	if (displayName) {
		displayNameSet = true;
	} else {
		displayNameSet = false;
		window.location.href = createRedirectPath('/')
	}

	refreshCookiesOnWebStart();

	// Get current device info.
	const device = deviceInfo();
	if (e2ee) {
		if ( device.isIPhone &&  !deviceWarningShown.current) {
			deviceWarningShown.current = true;
				setTimeout(() => {
							toast.warn("End-to-end encryption is not supported on iPhone devices.", {

								position: "top-right",
								autoClose: 8000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
						});
				}, 5000);
		} else if (!device.isChrome && !e2eeWarningShown.current) {
			e2eeWarningShown.current = true;
				setTimeout(() => {
					toast.warn("End-to-end encryption is supported only on Chrome browsers.", {
						position: "top-right",
								autoClose: 8000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
						});
				}, 5000);
		}
}

	store.dispatch(stateActions.setRoomUrl(roomUrl));

	store.dispatch(stateActions.setRoomFaceDetection(faceDetection));

	store.dispatch(stateActions.setMe({ peerId, displayName, displayNameSet, device }));

	store.dispatch(stateActions.webinarInfo({
		title: location?.state?.title,
		description: location?.state?.description,
		url: `${window.location.origin}${createRedirectPath("/join_meet?roomId=")}${roomId}`,
		meetType: location?.state?.meetType,
		userId: peerId,
		record: location?.state?.record,
		autoRecord: location?.state?.autoRecord,
		disableRecordPlayPause: location?.state?.disableRecordPlayPause,
		e2ee: location?.state?.e2ee
	}));

	if (!e2ee || (e2ee && e2eKey)) {
		roomClient = new RoomClient(
			{
				roomId,
				peerId,
				displayName,
				device,
				handlerName: handler,
				useSimulcast,
				useSharingSimulcast,
				forceTcp,
				produce,
				consume,
				forceH264,
				forceVP9,
				svc,
				datachannel,
				externalVideo,
				e2ee,
				role,
				protooUrl,
				e2eKey
			});

		window.CLIENT = roomClient;
		window.CC = roomClient;
	}

	return (
<>
			{
				e2ee ?
					<> {e2eKey ? <>
						<RoomContext.Provider value={roomClient}>
							<MobileRoom /*theme={theme}*/ />
						</RoomContext.Provider></> :
						<E2eModal openModal={e2eModal} setOpenModal={setE2eModal}  initial={true}/>}</> :
					<>
						<RoomContext.Provider value={roomClient}>
						<MobileRoom /*theme={theme}*/ />
						</RoomContext.Provider>
					</>
			}
		</>
	);
}
