import React from 'react'
import { Typography } from '@mui/material';

const Heading = ({ type = "heading", text = "" }) => {
  return (
    <>
      {type === "subHeading" ?
        <Typography
          variant='h6'
          component={"h6"}
          sx={{ color: 'grey.1000' }}>
          {text}
        </Typography>
        :
        <Typography
          variant='h3'
          component={"h3"}
          sx={{ color: 'grey.1000' }}
        >
          {text}
        </Typography>
      }
    </>
  )
}

export default Heading