import React from 'react';
import { Box, Typography } from "@mui/material";
import moment from 'moment';
import LoaderOnList from '../components/LoaderOnList';
import NoMoreDataShow from '../components/NoMoreDataShow';
import ApiErrorImgShow from '../components/ApiErrorImgShow';
import ReportActionsJsx from '../../../sections/web/Report/reportActions';

export default function ReportList({ wList, loadingWList, apiError }) {
  return (
    <>
      {wList.length > 0 && wList.map(({ title, createdByName, startDate, endDate, createdAt, duration, roomId, totalHostCount, recordings, totalRecordingSize }, index) => (
        <Box className='ListCardsGroup' key={index} sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: "4px",
        }}>
          <Box className='ListMeetingCard' sx={{
            px: '14px',
            py: '16px',
            backgroundColor: 'card.main',
            borderRadius: '10px',
            // borderColor: '#D3D8EB',
            borderWidth: '1px',
            borderStyle: 'solid',
            display: 'flex',
            flexDirection: 'column',
            gap: "14px",
          }}>
            <Box className='ListCardHeader' sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              color: 'grey.500',
            }}>

              <Typography sx={{
                fontSize: '13px',
                fontWeight: '600',
                lineHeight: '16px',
              }}>{title.charAt(0).toUpperCase() + title.slice(1) || ""}</Typography>

            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
            }} >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2px'
              }}>
                <Typography sx={{
                  color: 'grey.500',
                  fontSize: '10px',
                  fontWeight: 500
                }}>
                  Created by:
                </Typography>
                <Typography sx={{
                  color: 'grey.600',
                  fontSize: '10px',
                  fontWeight: 500
                }}>
                  {createdByName || ""}
                </Typography>
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: "flex-end",
                alignItem: "flex-end",
                flex: 1,
                gap: '2px'
              }}>
                <Typography sx={{
                  color: 'grey.500',
                  fontSize: '10px',
                  fontWeight: 500
                }}>
                  Scheduled Date and Time:
                </Typography>
                <Typography sx={{
                  color: 'grey.600',
                  fontSize: '10px',
                  fontWeight: 500
                }}>
                  {moment(createdAt).format('MMMM')} {new Date(createdAt).getDate()}, {moment(createdAt).year()}
                </Typography>
              </Box>
            </Box>

            <Box className='ListCardBody' sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
              color: 'grey.400',
              pb: 1
            }}>
              <Box className='ListMeetTime'>
                <Typography sx={{
                  fontSize: '11px',
                  fontWeight: '500',
                  color: 'grey.500'
                }}>Start/End Time:</Typography>
                <Typography sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                }}>{`${moment(startDate).format("hh:mm a")} to ${moment(endDate).format("hh:mm a")}`} </Typography>
              </Box>
              <Box className='ListMeetId' /* sx={{ pr: '5px' }} */>
                <Typography sx={{
                  fontSize: '11px',
                  fontWeight: '500',
                  color: 'grey.600',
                  display: 'flex',
                  flexDirection: 'column',
                  // alignItems: "flex-end",
                  // flex: 1
                }}>Room ID:</Typography>
                <Typography sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                }}>{roomId || ""} </Typography>
              </Box>
            </Box>

            <Box sx={{
              backgroundColor: 'grey.800',
              display: 'flex',
              p: 1,
              m: '-16px -14px -16px -14px',
              borderBottomRightRadius: '10px',
              borderBottomLeftRadius: '10px'
            }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                gap: '2px',
                alignItems: 'center'
              }}>
                <Typography sx={{
                  color: 'grey.400',
                  fontSize: '11px',
                  fontWeight: 500
                }}>
                  Panelist:
                </Typography>
                <Typography sx={{
                  color: 'grey.500',
                  fontSize: '10px',
                  fontWeight: 500
                }}>
                  {totalHostCount || "N/A"}
                </Typography>
              </Box>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'flex-end'
              }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '3px',
                  alignItems: 'center'
                }}>
                  <Typography sx={{
                    color: 'grey.400',
                    fontSize: '11px',
                    fontWeight: 500
                  }}>
                    Recordings:
                  </Typography>
                  <ReportActionsJsx recordings={recordings} totalRecordingSize={totalRecordingSize} />
                </Box>
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flex: 1,
                gap: '2px'
              }}>
                <Typography sx={{
                  color: 'grey.400',
                  fontSize: '11px',
                  fontWeight: 500
                }}>
                  Meeting Duration:
                </Typography>
                <Typography sx={{
                  color: 'grey.500',
                  fontSize: '10px',
                  fontWeight: 500,
                }}>
                  {duration} Min
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}

      {/* show loader when data loading. */}
      {loadingWList && <LoaderOnList />}

      {/* Show image and text when no more data available. */}
      {(!wList.length && !loadingWList && !apiError) && <NoMoreDataShow />}

      {/* Show network image when api throw error or any other error */}
      {(apiError) && <ApiErrorImgShow />}
    </>
  )
}
