import { Box, Typography, Container, Tabs, Tab, IconButton, Button, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WebinarList from '../../../components/mobile/listWebinar/WebinarList';
import SearchBar from '../../../components/mobile/listWebinar/SearchBar';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import TodayIcon from '@mui/icons-material/Today';
import { useNavigate } from 'react-router-dom';
import { axiosGetCall } from '../../../services/apisCall';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {/* <Typography> */}{children}{/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function MobileList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [wList, setWList] = useState([]);
  const [loadingWList, setLoadingWList] = useState(true)
  const [/* active */, setActive] = useState("all")
  // const [{ skip, size }, /* setListParams */] = useState({ skip: 20, size: 20 });
  const [/* hasMore */, setHasMore] = useState(false);
  const [apiError, setApiError] = useState(false)
  const [value, setValue] = useState(0);


  // Fetch schedule webinar list.
  const fetchList = async (skip = 0, size = 20, type = "all", search = null) => {
    try {
      let data = { skip, size, type };
      if (search) data.search = search;
      let res = await axiosGetCall("listWebinar",data)

      res?.result?.length < 20 ? setHasMore(false) : setHasMore(true)
      setLoadingWList(false)
      setWList(res?.result)
      // setWList(res?.result.concat(wList))
    } catch (error) {
      setApiError(true)
      setLoadingWList(false)
      renderErrorMessage("Unable to fetch scheduled meeting list!")
    }
  }

  const getPrevious = () => {
    setLoadingWList(true)
    setActive("previous");
    setWList([])
    fetchList(0, 20, "previous");

  }

  const getUpcoming = () => {
    setLoadingWList(true)
    setActive("upcoming");
    setWList([])
    fetchList(0, 20, "upcoming");
  }

  const getQuickMeetings = () => {
    setLoadingWList(true)
    setActive("quickmeetings");
    setWList([])
    fetchList(0, 20, "quickmeetings");
  }

  const getAll = () => {
    setLoadingWList(true)
    setActive("all");
    setWList([])
    fetchList(0, 20, "all");
  }

  const search = (e) => {
    if (e.key === 'Enter' && (e.target.value).trim() !== "") {
      setActive("all");
      setLoadingWList(true);
      setWList([])
      let txt = e.target.value
      let searchText = txt.toString().trim()
      fetchList(0, 20, "all", searchText);
    }
  }

  /*  const loadMoreData = () => {
     setLoadingWList(true);
     fetchList(skip, size, active);
   } */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const listSx = {
    backgroundColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
    borderRadius: '8px 8px 0px 0',
    height: 'calc(100vh - 265px)',
    padding: '0px 14px 70px 14px',
    display: 'flex',
    flexDirection: 'column',
    gap: "10px",
    overflowY: 'scroll',
    overflowX: 'hidden',
    borderTopWidth: '10px',
    borderColor: theme.palette.mode === 'dark' ? 'grey.1000' : '#E4E7F4',
    borderStyle: 'solid'
  }

  useEffect(() => {
    setHasMore(false)
    setLoadingWList(true)
    fetchList(0, 20, "all");
  }, [])

  return (
    <Box className='MobileWraper' sx={{
      overflow: 'hidden',
      width: "100%",
      height: '100vh',
      backgroundColor: 'background.default'
    }}>
      <ToastContainer />
      <Container /* maxWidth="xl" */ className='ListContainer' sx={{
        dispaly: 'flex',
        justifyContent: 'space-between',
        flexDirection: "row",
        '&.MuiContainer-root': {
          display: 'flex'
        },
      }} >
        <Box sx={{
          flexDirection: 'row',
          pt: 4,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }} className='ListGlobalBox'>
          <IconButton sx={{ width: '28px', height: '28px', color: '#1976d2' }} className='ListIconBtn'>
            <VideoCameraFrontIcon />
          </IconButton>
          <Typography className='ListTitle' sx={{
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '18px',
            color: 'grey.600',
            ml: 1
          }}>
            Meetings
          </Typography>
        </Box>



        <Box sx={{
          flexDirection: 'row',
          pt: 4,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }} className='ListGlobalBox'>
          <Button startIcon={<TodayIcon />} variant="text" sx={{
            textTransform: 'none'
          }} onClick={() => navigate('/schedule_meet')}>
            Schedule
          </Button>
        </Box>

      </Container>

      <Box sx={{ width: '100%', pt: 1 }} className='ListTabsBoxGlobal'>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='ListTabsBox'>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
            <Tab label="All" {...a11yProps(0)} onClick={() => getAll()} />
            <Tab label="Upcoming" {...a11yProps(1)} onClick={() => getUpcoming()} />
            <Tab label="Previous" {...a11yProps(2)} onClick={() => getPrevious()} />
            <Tab label="quickMeeting" {...a11yProps(3)} onClick={() => getQuickMeetings()} />

            
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} sx={{ width: '100%' }} className='ListTabPanel TabPanel1'>
          <SearchBar search={search} />
          <Box sx={listSx} className='ListWebinarBoxGlobal TabPanel1'>
            <WebinarList wList={wList} loadingWList={loadingWList} apiError={apiError} type={"scheduled"} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} sx={{ width: '100%' }} className='ListTabPanel TabPanel2'>
          <SearchBar search={search} />
          <Box sx={listSx} className='ListWebianrBoxGlobal TabPanel2'>
            <WebinarList wList={wList} loadingWList={loadingWList} apiError={apiError} type={"upcoming"} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} sx={{ width: '100%' }} className='ListTabPanel TabPanel3'>
          <SearchBar search={search} />
          <Box sx={listSx} className='ListWebinarBoxGlobal TabPanel3'>
            <WebinarList wList={wList} loadingWList={loadingWList} apiError={apiError} type={"previous"} />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={3} sx={{ width: '100%' }} className='ListTabPanel TabPanel4'>
          <SearchBar search={search} />
          <Box sx={listSx} className='ListWebinarBoxGlobal TabPanel3'>
            <WebinarList wList={wList} loadingWList={loadingWList} apiError={apiError} type={"quickmeetings"} />
          </Box>
        </TabPanel>
      </Box>
    </Box >
  )
}
