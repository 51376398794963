

import * as React from 'react';
import NewLeadDialog from './newLeadDialog';
import { LeadsContext } from '../../../context/leadsContext';
import { SOCKET_ENVENTS } from '../../../config/Enums';
import { renderErrorMessage } from '../../../components/ToastNotification';

export default function LeadsSection() {
    const [open, setOpen] = React.useState(false);
    const [leadData, setLeadData] = React.useState({})

    const { socket } = React.useContext(LeadsContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    React.useEffect(() => {
        try {
            if (socket) {
                socket.on(SOCKET_ENVENTS?.NEW_CALL, (newCall) => {
                    console.log("newCall", newCall)

                    const { callData = {} } = newCall || {};
                    setLeadData(callData);
                    handleClickOpen();

                    if (document.hidden) {
                        const n = new Notification("There is an active connection invite.", { body: window.location.href });

                        document.addEventListener("visibilitychange", () => {
                            if (document.visibilityState === "visible") {
                                // The tab has become visible so clear the now-stale Notification.
                                n.close();
                            }
                        });
                    }
                })
            }

        } catch (error) {
            console.error(error);
            renderErrorMessage("Somthing went wrong!")
        }

        // eslint-disable-next-line
    }, [socket])


    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen} sx={{ display: 'none' }}>
                Test popup open
            </Button> */}

            <NewLeadDialog
                open={open}
                setOpen={setOpen}
                data={leadData}
            />
        </React.Fragment>
    );
}

