import { Grid, Box, Typography, TextField, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import randomString from 'random-string';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { joinWebianrCheck } from '../../../services/commonApis';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';
import LoaderWithMessage from './LoaderWithMessage'
import { axiosGetCall, axiosPostCall } from '../../../services/apisCall';
import UrlParse from 'url-parse';

import { LoadingButton as Button } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { generateFingerprint, IPAddress, matchIsNumeric } from '../../../utils/common';

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({
    color: theme.palette.grey[200],
    "& .MuiInputBase-root": {
        color: theme.palette.grey[200],
    }
}))


export default function JoinLobby() {
    const urlParser = new UrlParse(window.location.href, true);
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [loaderMessage, setLoaderMessage] = useState("");
    const navigate = useNavigate();
    const [intervalId, setIntervalId] = useState(null);
    const [rejected, setRejected] = useState(false);
    const [otpLoader, setOtpLoader] = useState(false);
    const [otpEnter, setEnterOtp] = useState(false);
    const [ip, setIP] = useState("");
    const [fingerprint, setFingerprint] = useState("");

    const [callerId, setCallerId] = useState("")

    const [otp, setOtp] = React.useState('')

    const handleChange = (newValue) => {
        setOtp(newValue)
    }


    const validateChar = (value, index) => {
        return matchIsNumeric(value)
    }


    const checkStatus = async (callId) => {
        try {
            const { callInfo: { status = "", roomId } = {} } = await axiosGetCall("callStatus", { callId: callId || callerId, ip, fingerprint }) || {};

            if (status === 'running') {
                try {
                    const { redirectURL, state } = await joinWebianrCheck(roomId, name, peerId);
                    if (redirectURL) {
                        // setHasJoined(true);
                        clearInterval(intervalId);
                        navigate(redirectURL, { state });
                    } else {
                        renderErrorMessage("Invalid link!");
                    }
                    return
                } catch (error) {
                    renderErrorMessage("Error joining webinar");
                    return
                }
            } else if (status === "rejected") {
                renderErrorMessage("We are unable to connect you with an agent. Retry in a few minutes.");
                // setHasJoined(true);
                clearInterval(intervalId);
                setRejected(true);
                setLoaderMessage("")
            } else if (status === "completed") {
                renderErrorMessage("We are unable to connect you with an agent. Retry in a few minutes.");
                clearInterval(intervalId);
                setRejected(true);
                setLoaderMessage("")
            }
        } catch (error) {
            console.log(error)
            renderErrorMessage("Error checking meeting status");
        }
    };

    const joinCall = async () => {
        try {
            if (!name) return renderErrorMessage("Please enter your name");
            if (!mobile) return renderErrorMessage("Please enter your mobile number");
            if (!otp) return renderErrorMessage("Please enter otp");

            setJoinLoading(true)
            const payload = {
                "callerName": name,
                fingerprint,
                ip,
                mobile,
                otp,
            }

            const { callId = "" } = await axiosPostCall('joinCall', payload) || {};
            setCallerId(callId);
            setLoaderMessage("Please wait while we connect you with an agent.");
            setJoinLoading(false);
            const id = setInterval(() => checkStatus(callId), 5000);
            setIntervalId(id);

            urlParser.query.id = callId;
            window.history.pushState('', '', urlParser.toString());
        } catch (error) {
            console.log(error)
            renderErrorMessage(error?.message || "Somthing went wrong!");
            setJoinLoading(false);
        }
    }

    const handeleSendOtp = async () => {
        try {
            if (!name) return renderErrorMessage("Please enter your name");
            if (!mobile) return renderErrorMessage("Please enter your mobile number");

            setOtpLoader(true);

            const payload = {
                "name": "hello",
                fingerprint,
                // token: "",
                mobile,
                ip
            }

            await axiosPostCall('sendOtp', payload);
            setOtpLoader(false);
            setEnterOtp(true)
            renderSuccessMessage("OTP send to your mobile number!")
        } catch (error) {
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
            setOtpLoader(false);
        }
    }

    const fetchIP = async () => {
        const checkIP = await IPAddress()
        setIP(checkIP)

        const checkFingerprint = await generateFingerprint()
        setFingerprint(checkFingerprint)
    }


    useEffect(() => {

        fetchIP()
        const id = urlParser?.query?.id;
        if (id) {
            setCallerId(id);
            setLoaderMessage("Please hold on we will connect you with our agent soon!!");
            setJoinLoading(false);
            const iId = setInterval(() => checkStatus(id), 5000);
            setIntervalId(iId);
        } else {
            // joinCall()
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <ToastContainer />
            <Box sx={{
                backgroundColor: 'card.main',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                height: loaderMessage ? '660px' : '523px',
                width: '523px',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>

                <MeetLogoSvg width='auto' height="65px" />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '25px',
                    color: "grey.70",
                    mb: 1,
                    mt: 2
                }}>
                    Join Room
                </Typography>

                {joinLoading && <LoaderWithMessage message={"Please Wait"} />}

                {loaderMessage && <LoaderWithMessage message={loaderMessage} />}

                {!loaderMessage && <Box component='form' sx={{
                    width: '80%',
                    mt: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                }}>
                    <TextField
                        label="Please enter your name"
                        placeholder='Your good name please...'
                        size='medium'
                        type='text'
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{ input: { color: 'grey.600' } }}
                    />

                    <TextField
                        label="Please enter your mobile number"
                        placeholder='Your mobile number please...'
                        size='medium'
                        type='number'
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        disabled={otpEnter}
                        sx={{ input: { color: 'grey.600' } }}
                    />
                    {otpEnter && <>
                        <Typography sx={{ color: 'grey.600' }}>Please enter otp, sent to your mobile number</Typography>
                        <MuiOtpInputStyled
                            value={otp}
                            length={6}
                            onChange={handleChange}
                            sx={{ color: 'grey.600' }}
                            autoFocus
                            validateChar={validateChar}
                            TextFieldsProps={{ size: 'small', '& .MuiInputBase-root-MuiOutlinedInput-root': { color: '#fff' } }}
                        />
                    </>
                    }

                    <Button loading={otpLoader} variant='contained' onClick={() => otpEnter ? joinCall() : handeleSendOtp()}>
                        {otpEnter ? "Verify" : "Send OTP"}
                    </Button>
                </Box>}


                {rejected && <Typography sx={{
                    color: 'error.main',
                    maxWidth: '300px',
                    textAlign: 'center'
                }}>
                    Sorry we are not able to connect you with the agent. Please try again after some time!
                </Typography>}

            </Box>

        </Grid>
    )
}
