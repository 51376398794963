export function getConosleEnv() {
    return ["prod", "dev", "local"];
}

export const meetCode = 102;

const LOCAL_STORAGE = {
    BRANDING: 'branding'
}

const SOCKET_ENVENTS = {
    NEW_USER: 'newUser',
    NEW_CALL: 'newCall',
    ACCEPT_CALL: "acceptCall",
    PASS_CALL: "passCall",
    MARK_AS_JUNK: "markAsJunk",
    FETCH_CALL_LIST: "fetchCallList",
    FETCH_CALL_STATUS_COUNT: "fetchCallStatusCount",
}

const LEAD_PASS_TIME = 2 * 60 * 1000;

export {
    LOCAL_STORAGE,
    SOCKET_ENVENTS,
    LEAD_PASS_TIME
}
