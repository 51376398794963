import { Box, Card, CardContent, Grid, Typography, useTheme, useMediaQuery, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import shapSvg from './shapeSquare.svg'
import { SvgColor } from '../../../components/svg-color/svg-color';
import { Chart, useChart } from '../../../components/chart/';
import { Icon } from '@iconify/react';
import { LeadsContext } from '../../../context/leadsContext';
import { wsEmit } from '../../../lib/socketIo';
import { SOCKET_ENVENTS } from '../../../config/Enums';
import { renderErrorMessage, renderSuccessMessage } from '../../../components/ToastNotification';
import { useNavigate } from 'react-router-dom';
import randomString from 'random-string';
import { configFile } from '../../../config';
import { generateFingerprint, getMicAndCamPermission } from '../../../utils/common';

const { getProtooUrl } = configFile;

function bgGradient({ color, imgUrl }) {
    if (imgUrl) {
        return {
            background: `linear-gradient(${color}), url(${imgUrl})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        };
    }
    return { background: `linear-gradient(${color})` };
}

const CardsSection = () => {
    const [watingList, setWaitingList] = useState([])
    const [runningList, setRunningList] = useState([])
    const [allData, setAllData] = useState({})
    const { socket, name } = useContext(LeadsContext)
    const theme = useTheme();
    const navigate = useNavigate(0)
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const color = 'primary';

    const chart = {
        categories: ['Running', 'Junk', 'Waiting Lobby', 'Completed', "Not Connected"],
        // series: [15, 18, 12, 51, 100],
    }

    const chartColors = [theme.palette[color].dark, theme.palette.error.dark, 'rgb(39, 9, 122)', theme.palette.success.dark];

    const chartOptions = useChart({
        chart: { sparkline: { enabled: true } },
        colors: chartColors,
        stroke: { width: 0 },
        xaxis: { categories: chart.categories },
        tooltip: {
            y: { formatter: (value) => (value), title: { formatter: () => '' } },
        },
        plotOptions: { bar: { borderRadius: 1.5, columnWidth: '64%', distributed: false, } },
        ...chart.options,
    });

    const percent = 10
    const renderTrending = (id) => (
        <Box key={id} sx={{ gap: 0.5, display: 'flex', alignItems: 'center' }}>
            <Icon
                width={24}
                icon={
                    percent < 0
                        ? 'solar:double-alt-arrow-down-bold-duotone'
                        : 'solar:double-alt-arrow-up-bold-duotone'
                }
                style={{ flexShrink: 0, color: theme.palette.success.main, ...(percent < 0 && { color: theme.palette.error.main }) }}
            />

            {/*  <Box component="span" sx={{ typography: 'subtitle2' }}>
                {percent > 0 && '+'}
                {(percent)}
            </Box>
            <Box component="span" sx={{ typography: 'body2', }}>
                last 7 days
            </Box> */}
        </Box>
    );


    const fetchCounts = async (socket) => {
        try {
            const { data = [] } = await wsEmit(socket, SOCKET_ENVENTS?.FETCH_CALL_STATUS_COUNT, {})
            console.log(data);
            setAllData(data)
        } catch (error) {
            console.log(error);
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const fetchLebels = async (socket) => {
        try {
            const payload = { form: 0, size: 2, status: 'running' };
            const { callList = [] } = await wsEmit(socket, SOCKET_ENVENTS?.FETCH_CALL_LIST, payload);
            console.log("running", callList);
            setRunningList(callList)

            const payload1 = { form: 0, size: 2, status: 'pending' };
            const { callList: waiting } = await wsEmit(socket, SOCKET_ENVENTS?.FETCH_CALL_LIST, payload1);
            console.log("waiting", waiting);
            setWaitingList(waiting)
        } catch (error) {
            console.log(error);
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const handleJunk = async (callId) => {
        try {
            const { message } = await wsEmit(socket, SOCKET_ENVENTS?.MARK_AS_JUNK, { callId });

            renderSuccessMessage(message)

        } catch (error) {
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    const handleConnect = async (callId) => {
        try {
            const response = await wsEmit(socket, SOCKET_ENVENTS?.ACCEPT_CALL, { callId });

            const { title = "", description = "", isHostServer, record, autoRecord,
                disableRecordPlayPause, startDate, endDate, waitingRoom, e2ee,
                serverDetails, isWaitingRoom,
                roomId
            } = response;

            const role = "produce";
            const peerId = randomString({ length: 8 }).toLowerCase();
            // const name = randomString({ length: 6 }).toLowerCase();
            const fp = await generateFingerprint();

            const wssUrl = getProtooUrl({ roomId, peerId, serverDetails })

            getMicAndCamPermission();

            const redirectURL = `/meet?roomId=${roomId}&peerId=${peerId}`;
            const protooUrl = `${wssUrl}&h=${isHostServer}&fp=${fp}` || null;

            const state = {
                protooUrl, peerId, role, name, title, description, meetType: "meet", record, autoRecord,
                disableRecordPlayPause, startDate, endDate, roomId, waitingRoom, e2ee, isWaitingRoom
            };

            navigate(redirectURL, { state });

        } catch (error) {
            console.log(error)
            renderErrorMessage("Somthing went wrong!")
        }
    }

    function timeAgo(ts) {
        const now = new Date();
        const tsm = +new Date(ts)
        const differenceInSeconds = Math.floor((now - tsm) / 1000);

        const units = [
            { name: 'year', seconds: 31536000 },
            { name: 'month', seconds: 2592000 },
            { name: 'week', seconds: 604800 },
            { name: 'day', seconds: 86400 },
            { name: 'hour', seconds: 3600 },
            { name: 'minute', seconds: 60 },
            { name: 'second', seconds: 1 }
        ];

        for (let unit of units) {
            const interval = Math.floor(differenceInSeconds / unit.seconds);
            if (interval >= 1) {
                return interval === 1 ? `${interval} ${unit.name} ago` : `${interval} ${unit.name}s ago`;
            }
        }

        return 'Just now';
    }

    function copyToClip(roomId) {
        const text = `https://${window.location.hostname}/join_meet?roomId=${roomId}`

        navigator.clipboard.writeText(text);

        renderSuccessMessage("Link copid!")
    }

    useEffect(() => {
        if (socket) {
            fetchCounts(socket)
            fetchLebels(socket)

            socket.on("updateCallInfo", ({ callStatusCounts }) => {
                setAllData(callStatusCounts);
            });
        }
    }, [socket])

    return (
        <Grid container spacing={2} >
            <Grid item xs={matches ? 4 : 12}>
                <Card sx={{
                    ...bgGradient({
                        color: `135deg, rgba(205, 233, 253, 0.48), rgba(107, 177, 248, 0.48)`,
                    }),
                    color: `primary.darker`,
                    // backgroundColor: 'primary.lighter',
                }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box>
                            <Typography sx={{ typography: 'h6' }}>
                                Total
                            </Typography>

                            <Typography sx={{ color: 'grey.600', typography: 'h3' }}>
                                {allData?.total || 0}
                            </Typography>

                            {renderTrending("1")}
                        </Box>


                        <Box>
                            <Chart
                                type="bar"
                                series={[{ data: Object.keys(allData).length ? [allData?.running || 0, allData?.rejected || 0, allData?.pending || 0, allData?.completed || 0, allData?.disconnected || 0] : [0, 0, 0, 0, 0] }]}
                                options={chartOptions}
                                width={60}
                                height={40}
                            />
                        </Box>

                        <SvgColor
                            src={shapSvg}
                            sx={{
                                top: 0,
                                left: -20,
                                width: 240,
                                zIndex: -1,
                                height: 240,
                                opacity: 0.24,
                                position: 'absolute',
                                color: `${color}.main`,
                            }}
                        />
                    </CardContent>

                </Card>
            </Grid>
            <Grid item xs={matches ? 4 : 12} >
                <Card sx={{
                    ...bgGradient({
                        color: `135deg, rgba(239, 214, 255, 0.48), rgba(198, 132, 255, 0.48)`,
                    }),
                    color: `rgb(39, 9, 122)`,
                    // backgroundColor: 'rgb(142, 51, 255)',
                }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Box>
                            <Typography sx={{ /* color: 'grey.200', */ typography: 'h6' }}>
                                Waiting Lobby
                            </Typography>
                            <Typography sx={{ color: 'grey.600', typography: 'h3' }}>
                                {allData?.pending || 0}
                            </Typography>
                            {renderTrending("2")}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            gap: '6px'
                        }}>

                            {watingList.map(({ createdAt, id: callId }) => (
                                <Box key={createdAt} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '30px',
                                    padding: '5px 8px',
                                    border: '0.5px dashed',
                                    borderRadius: '6px',
                                    borderColor: 'purple.light'
                                }}>
                                    <Typography sx={{ color: 'grey.600', typography: 'body2' }}>{timeAgo(createdAt)}</Typography>

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <Tooltip title={"Connect"}>
                                            <Icon
                                                icon={"fluent-mdl2:join-online-meeting"}
                                                style={{ color: theme.palette.success.main }}
                                                onClick={() => handleConnect(callId)}
                                            />
                                        </Tooltip>

                                        <Tooltip title={"Mark Junk"}>
                                            <Icon
                                                icon={"ph:trash-bold"}
                                                style={{ color: theme.palette.error.main }}
                                                onClick={() => handleJunk(callId)}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            ))}

                        </Box>


                        <SvgColor
                            src={shapSvg}
                            sx={{
                                top: 0,
                                left: -20,
                                width: 240,
                                zIndex: -1,
                                height: 240,
                                opacity: 0.24,
                                position: 'absolute',
                                color: `${color}.main`,
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={matches ? 4 : 12}>
                <Card sx={{
                    ...bgGradient({
                        // color: `135deg, rgba(255, 245, 204, 0.48), rgba(255, 214, 102, 0.48)`,
                        color: `135deg, rgba(255, 233, 213, 0.48), rgba(255, 172, 130, 0.48)`,
                    }),
                    color: `error.darker`,
                    // color: `warning.darker`,
                    // backgroundColor: 'error.lighter',
                }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }} >
                        <Box>
                            <Typography sx={{ /* color: 'grey.200', */ typography: 'h6' }}>
                                Running Meetings
                            </Typography>
                            <Typography sx={{ color: 'grey.600', typography: 'h3' }}>
                                {allData?.running || 0}
                            </Typography>
                            {renderTrending("3")}
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            gap: '6px'
                        }}>

                            {runningList.map(({ agentName, roomId }, i) => (
                                <Box key={i + 10} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '30px',
                                    padding: '5px 8px',
                                    border: '0.5px dashed',
                                    borderRadius: '6px',
                                    borderColor: 'error.light'
                                }}>
                                    <Typography sx={{ color: 'grey.600', typography: 'body2' }}>{agentName}</Typography>

                                    <Tooltip title={"Copy room link"}>
                                        <Icon
                                            icon={"tabler:copy"}
                                            style={{ color: theme.palette.error.main }}
                                            onClick={() => copyToClip(roomId)}
                                        />
                                    </Tooltip>

                                </Box>
                            ))}
                        </Box>


                        <SvgColor
                            src={shapSvg}
                            sx={{
                                top: 0,
                                left: -20,
                                width: 240,
                                zIndex: -1,
                                height: 240,
                                opacity: 0.24,
                                position: 'absolute',
                                color: `${color}.main`,
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>

            {/* <Grid item xs={2.66}>
                <Card sx={{
                    ...bgGradient({
                        color: `135deg, rgba(255, 245, 204, 0.48), rgba(255, 214, 102, 0.48)`,
                    }),
                    color: `warning.darker`,
                }}>
                    <CardContent>
                        <Typography sx={{ typography: 'h6' }}>
                            Running Meetings
                        </Typography>
                        <Typography sx={{ color: 'grey.100', typography: 'h3' }}>
                            1000
                        </Typography>
                        {renderTrending}

                        <SvgColor
                            src={shapSvg}
                            sx={{
                                top: 0,
                                left: -20,
                                width: 240,
                                zIndex: -1,
                                height: 240,
                                opacity: 0.24,
                                position: 'absolute',
                                color: `${color}.main`,
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid> */}

        </Grid>
    )
}

export default CardsSection