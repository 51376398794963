import React, { useEffect, useState, useMemo } from "react";
import './assets/css/App.css';
import Logger from "./lib/Logger";
import { createBrowserRouter, RouterProvider, createRoutesFromElements } from "react-router-dom";
import AllRoutes from './lib/Routes';
import santry from "./lib/santry";
import { ColorModeContext } from './context/ThemeContext';
import { configFile } from './config';

// theme
import ThemeProvider from './theme';
import { getItem } from "./lib/cookiesManager";
import UserGuides from "./lib/userGuides";
import checkBranding from "./lib/branding";
import { useDispatch } from "react-redux";
import { updateBranding } from "./redux/actions/brandingActions";

// Date range css
import 'rsuite/styles/index.less';

const logger = new Logger("App.js")
const { SANTRY } = configFile;

function App() {
  const [mode, setMode] = useState(getItem("theme") || "light");
  const dispatch = useDispatch();

  // handle theme change
  const colorMode = useMemo(() => ({
    toggleColorMode: () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    },
  }), []);

  // const theme = useMemo(() => dynamicTheme(mode), [mode]);

  const router = createBrowserRouter(createRoutesFromElements(AllRoutes()), {
    basename: process.env.REACT_APP_DOMAIN_SUBDIRECTORY ? process.env.REACT_APP_DOMAIN_SUBDIRECTORY : ""
  });

  const fetchBranding = async () => {
    try {
      const data = await checkBranding();
      dispatch(updateBranding(data));
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const env = process.env.REACT_APP_ENV || 'local';
    const guide = getItem("guide");

    fetchBranding()

    // document.title = "HD Video Conferencing Solution - Sarv Wave by Sarv.com"

    // for user guides on new users
    if (!guide) UserGuides()

    //enable santry log in case of production env.
    if (env === 'prod' && SANTRY) { santry() }


    if (env === "prod") {
      document.addEventListener('contextmenu', function (e) {
        e.preventDefault();
      });

      document.onkeydown = function (e) {
        if (e.key === "F12") return false;

        if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) return false;

        if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) return false;

        if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) return false;

        if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) return false;

      }
    }

    window.addEventListener('error', (event) => {
      logger.debug("Error caught on eventListener app.js", event)
    });
    return () => {
      window.removeEventListener('error', (event) => {
        logger.debug("Error caught on eventListener app.js", event)
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App" id="App">
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider mode={mode}>
            <RouterProvider router={router} />
          </ThemeProvider>
      </ColorModeContext.Provider>
    </div >
  );
}

export default App;
