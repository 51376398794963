// import io from 'socket.io-client';
import { configFile } from '../config';
import { getItem } from '../lib/cookiesManager';

const { getApiUrl } = configFile;

const connectWs = () => {
    const url = getApiUrl();
    const token = getItem("sessionToken");
    /*  const newSocket = io(url, {
         path: "/meet-api/socket.io",
         // autoConnect: false,
         transports: ['websocket', 'polling'],
         auth: {
             token
         }
     }
     ) */
    return { url, token };
}

const wsEmit = (conn, evnetName, data) => {
    return new Promise((resolve, reject) => {
        try {
            conn.emit(evnetName, data, ({ message, error, data, ...others }) => {
                if (error) return reject(message);
                console.log("ws emit: ", evnetName, message, error);
                resolve({ message, data, ...others });
            });
        } catch (error) {
            reject(error);
        }
    })
}

export {
    connectWs,
    wsEmit
}