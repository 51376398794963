import React, { Component, memo } from 'react';
import * as cookiesManager from '../../../lib/cookiesManager';
import Draggable from 'react-draggable';
import { Fab, Tooltip, Zoom, Menu, MenuItem, Modal, Box, IconButton, Typography, styled, tooltipClasses } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import MenuIcon from '@mui/icons-material/Menu';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import CallEndIcon from '@mui/icons-material/CallEnd';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DevicesMenu from './Menu/DevicesMenu';
import { renderErrorMessage } from '../../../components/ToastNotification';
import LoadingButton from '@mui/lab/LoadingButton';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { configFile } from '../../../config';
import { connect } from 'react-redux';
import MicAndVideoPermissionBlock from './Modal/MicAndVideoPermissionBlock';
import ManageAllExternelCases from './ManageAllExternelCases';
import { axiosPostCall } from '../../../services/apisCall';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreOptionsMenu from './Menu/MoreOptionsMenu';
import { endRoom } from '../../../services/commonApis';
import { createRedirectPath } from '../../../utils/common';

const { removeMicOnWarningTime, showMicOnWarningTime } = configFile;
const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 210,
        backgroundColor: '#737070'
    },
});
class SideBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            controlledPosition: {
                x: null,
                y: null
            },
            styleNavGrid: {
                width: '3.8%',
                bgcolor: '#000',
                color: '#ffffff',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 499
            },
            styleMainGrid: {
                width: '96.2%',
                height: '100vh',
                color: '#ffffff',
                backgroundColor: '#151515',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                margin: '0px',
                px: '5px',
                py: '5px',
                alignItems: 'stretch',
            },
            fadSx: {
                active: { my: 0.9 }
            },
            orderSidebar: 1,
            open: false,
            openChat: false,
            anchorElMore: null,
            openEndAll: false,
            openLeave: false,
            endSuccessLoading: false,
            menuBefore: {
                marginLeft: 1,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 24,
                    left: -5,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            deviceMenuSx: {
                marginLeft: 2,
                marginTop: -1,
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                },
                '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 18,
                    left: -3,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                },
            },
            transformOrigin: { horizontal: 'left', vertical: 'top' },
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            dragActive: false,
            disableWebCamIcon: false,
            devices: [],
            anchorElDevices: false,
            deviceMenu: { title: "Camera", type: "video" },
            isSpeaking: false,
            dragCursor: 'grab',
            permissionBlock: false,
            micBlock: true,
            cameraBlock: true,
            promoteToHostState: false,
            videoDevice: true,
            audioDevice: true
        }
    }

    onControlledDrag = (e, position) => {
        const { x, y } = position;
        this.setState({ dragActive: true, dragCursor: 'grabbing' })

        if (this.state.orderSidebar === 2) {
            if (x < 0) {
                this.setState({ controlledPosition: { x, y }, styleNavGrid: { ...this.state.styleNavGrid, height: "80vh", borderRadius: 5 } })
            } else if (y < 0) {
                this.setState({ controlledPosition: { x, y }, styleNavGrid: { ...this.state.styleNavGrid/* , height: "10vh" */, width: '80%', borderRadius: 5 } })
            }
        } else {
            let { mx } = this.state.fadSx.active;

            if (mx) {
                return this.setState({ controlledPosition: { x, y }, styleNavGrid: { ...this.state.styleNavGrid/* , height: "10vh" */, width: '80%', borderRadius: 5 } })
            }
            if (x > 0) {
                this.setState({ controlledPosition: { x, y }, styleNavGrid: { ...this.state.styleNavGrid, height: "80vh", borderRadius: 5 } })
            } else if (y > 0 && x < 0) {  //changed x<=0 to x<0
                this.setState({ controlledPosition: { x, y }, styleNavGrid: { ...this.state.styleNavGrid/* , height: "10vh" */, width: '80%', borderRadius: 5 } })
            }
        }
    };

    onStop = () => {
        let { x, y } = this.state.controlledPosition;
        let { dimensions: { width, height }, changeOrder } = this.props;
        let wdm30 = Math.round((width / 100) * 30) // 30% of width..
        let wdm70 = Math.round((width / 100) * 70)
        let hdm30 = Math.round((height / 100) * 30);
        let hdm70 = Math.round((height / 100) * 70);
        let styleMainGrid = {}
        let menuSx = {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
            },
        }

        let menuBeforeSx = {
            content: '""',
            display: 'block',
            position: 'absolute',
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        }
        if (this.state.orderSidebar === 2) {
            if (x < 0) {
                height = height - Math.round((height / 100) * 80)
                hdm30 = Math.round((height / 100) * 30)
                hdm70 = Math.round((height / 100) * 70)
            } else if (y < 0) {
                width = width - Math.round((width / 100) * 80)
                wdm30 = Math.round((width / 100) * 30)
                wdm70 = Math.round((width / 100) * 70)
            }
        }
        else {
            let { mx } = this.state.fadSx.active;

            if (!mx && x > 0) {
                height = height - Math.round((height / 100) * 80)
                hdm30 = Math.round((height / 100) * 30)
                hdm70 = Math.round((height / 100) * 70)
            } else if (y > 0 && x <= 0) {
                width = width - Math.round((width / 100) * 80)
                wdm30 = Math.round((width / 100) * 30)
                wdm70 = Math.round((width / 100) * 70)
            }
        }

        // right > bottom > left > top conditions 

        if ((x < -50 && y < hdm30) || (y < -50 && x > wdm30 && x < wdm70) || (x > 50 && y < 40 && y >= 0)) { //top
            styleMainGrid = { ...this.state.styleMainGrid, width: '100%', height: '93.2vh' }
            changeOrder(2, styleMainGrid)
            this.setState({
                styleNavGrid: { ...this.state.styleNavGrid, height: "6.8vh", width: '100%', flexDirection: 'row', borderRadius: 0 },
                controlledPosition: { x: 0, y: 0 },
                fadSx: { active: { mx: 0.9 } },
                orderSidebar: 1,
                menuBefore: {
                    ...menuSx,
                    mt: 1,
                    ml: 0,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 0,
                        right: 20,
                        width: 10,
                        height: 10,
                    },
                },
                transformOrigin: { horizontal: 'right', vertical: 'top' },
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                deviceMenuSx: {
                    ...menuSx,
                    mt: 2,
                    ml: 1.5,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 0,
                        right: 15,
                        width: 10,
                        height: 10,
                    },
                },
                dragCursor: 'grab'
            })
        } else if ((x < -50 && y > hdm70) || (x > 50 && y > hdm70 && y < 133) || (y > 100 && x > 60 && x < 200)) { // bottom
            styleMainGrid = { ...this.state.styleMainGrid, width: '100%', height: '93.2vh' }
            changeOrder(1, styleMainGrid)
            this.setState({
                styleNavGrid: { ...this.state.styleNavGrid, height: "6.8vh", width: '100%', flexDirection: 'row', borderRadius: 0 },
                controlledPosition: { x: 0, y: 0 },
                fadSx: { active: { mx: 0.9 } },

                orderSidebar: 2,
                menuBefore: {
                    ...menuSx,
                    mt: -1,
                    '&:before': {
                        ...menuBeforeSx,
                        bottom: -10,
                        right: 70,
                        width: 10,
                        height: 10,
                    },
                },
                transformOrigin: { horizontal: 'top', vertical: 'bottom' },
                anchorOrigin: { horizontal: 'top', vertical: 'right' },
                deviceMenuSx: {
                    ...menuSx,
                    mt: -2,
                    ml: -2,
                    '&:before': {
                        ...menuBeforeSx,
                        bottom: -10,
                        // right: 300,
                        left: 19,
                        width: 10,
                        height: 10,
                    },
                },
                dragCursor: 'grab'
            })
        } else if ((x < -50 && y > hdm30 && y < hdm70) || (x < wdm30 && y < -50) || (x < 60 && y > 50)) { // left
            styleMainGrid = { ...this.state.styleMainGrid, width: '96.2%', height: '100vh' }
            changeOrder(2, styleMainGrid)
            this.setState({
                styleNavGrid: { ...this.state.styleNavGrid, height: "100vh", width: '3.8%', flexDirection: 'column', borderRadius: 0 },
                controlledPosition: { x: 0, y: 0 },
                fadSx: { active: { my: 0.9 } },
                orderSidebar: 1,
                menuBefore: {
                    ...menuSx,
                    marginLeft: 2,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 23,
                        left: -5,
                        width: 10,
                        height: 10,
                    },
                },
                transformOrigin: { horizontal: 'left', vertical: 'top' },
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                deviceMenuSx: {
                    ...menuSx,
                    marginLeft: 2,
                    marginTop: -1,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 18,
                        left: -3,
                        width: 10,
                        height: 10,
                    },
                },
                dragCursor: 'grab'
            })
        } else if ((x > wdm70 && y < -50) || (x > 50 && y > hdm30 && y < hdm70) || (x > 200 && y > 50)) {// right
            styleMainGrid = { ...this.state.styleMainGrid, width: '96.2%', height: '100vh' }
            changeOrder(1, styleMainGrid)
            this.setState({
                styleNavGrid: { ...this.state.styleNavGrid, height: "100vh", width: '3.8%', flexDirection: 'column', borderRadius: 0 },
                controlledPosition: { x: 0, y: 0 },
                fadSx: { active: { my: 0.9 } },
                orderSidebar: 2,
                menuBefore: {
                    ...menuSx,
                    ml: -1,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 25,
                        right: -5,
                        width: 10,
                        height: 10,
                    },
                },
                transformOrigin: { horizontal: 'right', vertical: 'top' },
                anchorOrigin: { horizontal: 'bottom', vertical: 'top' },
                deviceMenuSx: {
                    ...menuSx,
                    ...menuSx,
                    ml: -2,
                    mt: -1.2,
                    '&:before': {
                        ...menuBeforeSx,
                        top: 18,
                        right: -3,
                        width: 10,
                        height: 10,
                    },
                },
                dragCursor: 'grab'
            })
        } else {
            let { height } = this.state.styleNavGrid;
            this.setState({
                controlledPosition: { x: 0, y: 0 },
                styleNavGrid: {
                    ...this.state.styleNavGrid,
                    height: height === '80vh' ? "100vh" : height === '100vh' ? '100vh' : '6.8vh',
                    width: height === '6.8vh' ? '100%' : '3.8%', borderRadius: 0
                },
                dragActive: true,
                dragCursor: 'grab'
            })
        }
    };

    handleClick = (event) => {
        this.setState({ anchorElMore: event.currentTarget })
    };
    handleClose = () => {
        this.setState({ anchorElMore: null })
    };
    handleClickEnd = (event) => {
        this.setState({ anchorElEnd: event.currentTarget })
    };
    handleCloseEnd = () => {
        this.setState({ anchorElEnd: null })
    };

    // fn to end webinar for all peers
    endWebinar = async (e) => {
        e.preventDefault();
        this.setState({ endSuccessLoading: true });
        try {
            const { _roomId: roomId, _peerId: peerId } = this.props.roomClient;
            await axiosPostCall("endCall", { roomId, peerId })
            this.setState({ endSuccessLoading: false })
        } catch (error) {
            this.setState({ endSuccessLoading: false })
            console.error(error.message);
            renderErrorMessage(error.message)
        }
    }

    handleClickVideoDevices = (event, title, type) => {
        this.setState({ anchorElDevices: event.currentTarget, deviceMenu: { title, type } })
    }

    // Checking mic and camera permission in case of permission block
    checkPermission = async () => {
        try {
            const PermissionStatus = await navigator.permissions.query({
                name: "microphone",
            });
            const cameraPermission = await navigator.permissions.query({
                name: "camera",
            });

            if (PermissionStatus.state === "denied") {
                this.setState({ micBlock: false })
            }
            if (cameraPermission.state === "denied") {
                this.setState({ cameraBlock: false })
            }
            return
        } catch (error) {
            console.error(error);
            return
        }
    }

    handleEnableMic = async () => {
        const { roomClient, micState, micAndCamPermissions } = this.props;
        const { micBlock } = this.state;
        if (!micAndCamPermissions?.mic || !micBlock) {
            await this.checkPermission()
            return this.setState({ permissionBlock: true })
        }
        if (micState === 'on') {
            cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: false });
            roomClient.muteMic()
        } else {
            this.setState({ isSpeaking: false })
            cookiesManager.setDevices({ webcamEnabled: cookiesManager.getDevices().webcamEnabled, audioEnabled: true });
            roomClient.unmuteMic();
        }
    }

    handleEnableCam = async () => {
        const { roomClient, webcamState, micAndCamPermissions, virtualBackground, vbImageUrl } = this.props;
        const { cameraBlock } = this.state;
        if (!micAndCamPermissions?.camera || !cameraBlock) {
            await this.checkPermission()
            return this.setState({ permissionBlock: true })
        }
        if (webcamState === 'on') {
            cookiesManager.setDevices({ webcamEnabled: false, audioEnabled: cookiesManager.getDevices().audioEnabled });
            roomClient.disableWebcam();
        }
        else {
            cookiesManager.setDevices({ webcamEnabled: true, audioEnabled: cookiesManager.getDevices().audioEnabled });
            if (this.state.disableWebCamIcon) {
                return;
            }
            this.setState({ disableWebCamIcon: true })
            roomClient.enableWebcam(virtualBackground, vbImageUrl);
            // setTimeout(() => {
            //     this.setState({ disableWebCamIcon: false })
            // }, 4000)
        }
    }

    checkAudioVideoDevices = async () => {
        try {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();

            const checkVideoDevice = mediaDevices.find(({ kind: kindFilter }) => kindFilter === "videoinput")?.kind;
            const checkAudioDevice = mediaDevices.find(({ kind: kindFilter }) => kindFilter === "audioinput")?.kind;
            if (!checkVideoDevice) this.setState({ videoDevice: false });
            if (!checkAudioDevice) this.setState({ audioDevice: false });

            console.log("mediaDevices", mediaDevices, checkVideoDevice, checkAudioDevice)

        } catch (error) {
            console.log("@sidebar device find error", error)
        }
    }

    endMeet = async () => {
        const { peersCount } = this.props;
        const { _roomId: roomId, _peerId: peerId } = this.props.roomClient;
        if (!peersCount) {
            await endRoom(roomId, peersCount, peerId);
        }
        const redirectTo = cookiesManager.getItem("sessionToken") ? process.env?.REACT_APP_END_REDIRECTION ?  "/leads" : "/" : process.env?.REACT_APP_END_ROOM_URL === "active" ? "/end_meet_up112" : "/end_meet";
        window.location.href = createRedirectPath(redirectTo);
        }

    componentDidMount() {
        const handleAudioData = (dataArray) => {
            const normalizedArray = Array.from(dataArray, (value) => value / 255);
            const isSpeaking = normalizedArray.some((value) => value > 0.5) && this.props.micState !== 'on';
            this.setState({ isSpeaking })
        };

        this.checkAudioVideoDevices()

        navigator.mediaDevices.getUserMedia({/*  audio: true, */ audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            googEchoCancellation: true,
            googAutoGainControl: true,
            googNoiseSuppression: true,
            googHighpassFilter: true,
            googTypingNoiseDetection: true,
            googNoiseReduction: true,
            // volume: 1.0,
        }
        }).then((stream) => {
            const audioContext = new AudioContext();
            const analyserNode = audioContext.createAnalyser();
            const sourceNode = audioContext.createMediaStreamSource(stream);

            sourceNode.connect(analyserNode);
            analyserNode.connect(audioContext.destination);
            analyserNode.disconnect(audioContext.destination);

            const dataArray = new Uint8Array(analyserNode.frequencyBinCount);

            const intervalId = setInterval(() => {
                analyserNode.getByteTimeDomainData(dataArray);
                handleAudioData(dataArray);
                setTimeout(() => {
                    this.setState({ isSpeaking: false })
                }, removeMicOnWarningTime);
            }, showMicOnWarningTime);

            return () => {
                clearInterval(intervalId);
                audioContext.close();
                stream.getTracks().forEach((track) => track.stop());
            };
        }).catch((error) => console.log("Sidebar.jsx componentDidMount getUserMedia", error));

        this.checkPermission()

    }

    componentDidUpdate() {
        const { disableWebCamIcon, promoteToHostState } = this.state;
        const { webcamState, promoteToHost, roomClient, webcamInProgress } = this.props;
        if ((webcamState === 'on' && disableWebCamIcon) || (!webcamInProgress && disableWebCamIcon)) {
            this.setState({ disableWebCamIcon: false })
        }

        if (promoteToHost && !promoteToHostState) {
            this.setState({ promoteToHostState: true });
            roomClient.promoteAttendeeToHost()
        }

        if (!promoteToHost && promoteToHostState) {
            this.setState({ promoteToHostState: false });
        }
    }

    render() {
        const { roomClient, micState, webcamState, openChat, isNewMessage,
            shareState, screenShare, open, handleDrawerOpen, handleDrawerClose,
            handleChatDrawerClose, handleChatDrawerOpen, closeChatTab, roomState,
            meetType, micAndCamPermissions, promoteToHost, webcamInProgress } = this.props;
        const { fadSx, isSpeaking, disableWebCamIcon, micBlock, cameraBlock, videoDevice, audioDevice } = this.state;

        return (
            <>
                <Draggable bounds="parent" handle="strong" position={this.state.controlledPosition} onDrag={this.onControlledDrag} onStop={this.onStop}>
                    <Grid sx={{ ...this.state.styleNavGrid }} style={{ order: this.state.orderSidebar }}>

                        <strong >
                            <Tooltip TransitionComponent={Zoom} title="Drag and Drop" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                <Fab size="small" aria-label="add" disableRipple disableFocusRipple className='audioGroup'
                                    sx={{
                                        ...this.state.fadSx.active,
                                        bgcolor: "transparent",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        top: this.state.fadSx.active?.mx ? 'calc(3.4vh - 20px)' : '0px',
                                        // left: this.state.dragActive ? (this.state.fadSx.active?.mx ? '0px' : "calc(1.4% + 6px)") : "calc(1.4% - 13px)",
                                        left: this.state.dragActive ? (this.state.fadSx.active?.mx ? '16px' : "50%") : "calc(1% + 13px)",
                                        // left: '50%',
                                        color: 'rgba(255,255,255,.4)',
                                        // transform: this.state.fadSx.active?.mx ? 'rotate(90deg)' : 'rotate(0deg)',
                                        transform: this.state.fadSx.active?.mx ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0deg)',
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                        },
                                        flexDirection: 'column',
                                        cursor: this.state?.dragCursor
                                    }}>
                                    <DragHandleIcon sx={{ width: '35px', height: '35px', order: this.state.fadSx.active?.mx ? 2 : 1, }} />
                                </Fab>
                            </Tooltip>
                        </strong>

                        {(roomClient?._role === 'produce' || promoteToHost) && (
                            <>
                                <Tooltip TransitionComponent={Zoom} title="Turn on microphone" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                    <Box sx={{ width: this.state.fadSx.active?.mx ? "none" : '100%', display: 'flex', justifyContent: 'center' }}>
                                        <CustomWidthTooltip
                                            TransitionComponent={Zoom}
                                            title={
                                                <>
                                                    <CloseIcon sx={{ float: 'right', width: '3.5vh', height: '3.5vh', p: '4px' }} onClick={() => this.setState({ isSpeaking: false })}></CloseIcon>
                                                    <Typography sx={{ fontSize: '12px', p: '5px' }}>Are you talking? Your mic is off. Click the mic to turn it on.</Typography>
                                                </>
                                            }
                                            arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}
                                            open={isSpeaking}
                                        >
                                            <Fab color='primary'
                                                size="small" aria-label="add"
                                                className='micGroup'
                                                sx={{
                                                    ...this.state.fadSx.active,
                                                    bgcolor: !micBlock || !micAndCamPermissions?.mic || !audioDevice ? '#8b0000' : (micState === 'on' ? '#1770F6' : 'rgba(255,255,255,.2)'),
                                                    display: 'flex',
                                                    flexDirection: fadSx.active?.mx ? 'row' : 'column',
                                                    gap: '10px',
                                                    // padding: fadSx.active?.mx ? '0 15px 0 12px' : '12px 0 15px 0',
                                                    height: fadSx.active?.mx ? '40px' : 'auto',
                                                    width: fadSx.active?.mx ? 'auto' : '40px',
                                                    borderRadius: '100px',
                                                    opacity: !micBlock || !micAndCamPermissions?.mic || !audioDevice ? 0.6 : (roomState === "connected" ? 1 : 0.6)
                                                }}>
                                                {micState === 'on' ?
                                                    <MicIcon
                                                        sx={{
                                                            padding: fadSx.active?.mx ? '0 0 0 10px' : '10px 0 0 0',
                                                            width: '1.2em',
                                                            height: '1.3em',
                                                        }}
                                                        onClick={() => roomState === "connected" ? this.handleEnableMic() : ""}
                                                    />
                                                    :
                                                    <MicOffIcon
                                                        sx={{
                                                            padding: fadSx.active?.mx ? '0 0 0 10px' : '10px 0 0 0',
                                                            width: '1.2em',
                                                            height: '1.3em',
                                                        }}
                                                        onClick={() => roomState === "connected" ? this.handleEnableMic() : ""}
                                                    />}
                                                < MenuIcon
                                                    sx={{
                                                        padding: fadSx.active?.mx ? '0 15px 0 0' : '0 0 15px 0',
                                                        width: '1.2em',
                                                        height: '1.2em',
                                                    }}
                                                    aria-label="add"
                                                    onClick={(e) => this.handleClickVideoDevices(e, "Microphones", "audio")}
                                                    aria-controls={Boolean(this.state.anchorElDevices) ? 'video-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={Boolean(this.state.anchorElDevices) ? 'true' : undefined}
                                                />

                                            </Fab>
                                        </CustomWidthTooltip>
                                    </Box>
                                </Tooltip>
                                <Tooltip TransitionComponent={Zoom} title="Video" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                    <LoadingButton
                                        loading={disableWebCamIcon || webcamInProgress ? true : null}
                                        loadingPosition="center"
                                        className='videoGroup'
                                        sx={{
                                            minWidth: 'auto',
                                            ...this.state.fadSx.active,
                                            background: !cameraBlock || !micAndCamPermissions?.camera || !videoDevice ? '#8b0000' : (webcamState === 'on' ? '#1770F6' : disableWebCamIcon ? '#fff' : 'rgba(255,255,255,.2)'),
                                            "&:hover": {
                                                background: disableWebCamIcon ? '#fff' : '#1565c0',
                                            },
                                            display: 'flex',
                                            flexDirection: fadSx.active?.mx ? 'row' : 'column',
                                            gap: '10px',
                                            // padding: fadSx.active?.mx ? '0 15px 0 12px' : '12px 0 15px 0',
                                            width: fadSx.active?.mx ? 'auto' : '40px',
                                            height: fadSx.active?.mx ? '40px' : 'auto',
                                            borderRadius: '100px',
                                            opacity: !cameraBlock || !micAndCamPermissions?.camera || !videoDevice ? 0.6 : (roomState === "connected" ? 1 : 0.6),
                                            color: '#fff'
                                        }}
                                    >
                                        {webcamState === 'on' ?
                                            <VideocamIcon
                                                sx={{
                                                    padding: fadSx.active?.mx ? '0 0 0 6px' : '6px 0 0 0',
                                                    width: '1.2em',
                                                    height: '1.1em',
                                                }}
                                                onClick={() => roomState === "connected" ? this.handleEnableCam() : ""}
                                            />
                                            :
                                            <VideocamOffIcon
                                                sx={{
                                                    padding: fadSx.active?.mx ? '0 0 0 6px' : '6px 0 0 0',
                                                    width: '1.2em',
                                                    height: '1.1em',
                                                }}
                                                onClick={() => roomState === "connected" ? this.handleEnableCam() : ""}
                                            />}
                                        <MenuIcon
                                            sx={{
                                                padding: fadSx.active?.mx ? '0 10px 0 0' : '0 0 10px 0',
                                                width: '1.2em',
                                                height: '1em',
                                            }}
                                            aria-label="add"
                                            onClick={(e) => this.handleClickVideoDevices(e, "Camera", "video")}
                                            aria-controls={Boolean(this.state.anchorElDevices) ? 'video-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(this.state.anchorElDevices) ? 'true' : undefined}
                                        />
                                    </LoadingButton>
                                </Tooltip>

                                {/*  <Tooltip TransitionComponent={Zoom} title="Video" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                    <Fab color='primary' size="small" aria-label="add"
                                        // onClick={() => this.handleEnableCam}
                                        sx={{
                                            ...this.state.fadSx.active,
                                            bgcolor: webcamState === 'on' ? '#1770F6' : 'rgba(255,255,255,.2)',
                                            display: 'flex',
                                            flexDirection: fadSx.active?.mx ? 'row' : 'column',
                                            gap: '10px',
                                            padding: fadSx.active?.mx ? '0 15px 0 12px' : '12px 0 15px 0',
                                            height: fadSx.active?.mx ? '' : 'auto',
                                            width: fadSx.active?.mx ? 'auto' : '70%',
                                            borderRadius: '100px',
                                            opacity: roomState === "connected" ? 1 : 0.6
                                        }} >
                                        {webcamState === 'on' ? <VideocamIcon onClick={() => roomState === "connected" ? this.handleEnableCam() : ""} />
                                            : <VideocamOffIcon onClick={() => roomState === "connected" ? this.handleEnableCam() : ""} />}
                                        <MenuIcon
                                            sx={{ width: '16px', height: '16px' }}
                                            aria-label="add"
                                            onClick={(e) => this.handleClickVideoDevices(e, "Camera", "video")}
                                            aria-controls={Boolean(this.state.anchorElDevices) ? 'video-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(this.state.anchorElDevices) ? 'true' : undefined}
                                        />
                                    </Fab>
                                </Tooltip> */}

                                {!promoteToHost && <> <Tooltip TransitionComponent={Zoom} title="Participants" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                    <Fab
                                        color='primary'
                                        size="small"
                                        aria-label="add"
                                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                                        className='participants'
                                        sx={{
                                            ...this.state.fadSx.active,
                                            bgcolor: open ? '#1770F6' : 'rgba(255,255,255,.2)',
                                        }}>
                                        <PeopleAltIcon />
                                    </Fab>
                                </Tooltip>

                                    <Tooltip TransitionComponent={Zoom} title="Chat" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                        <Fab color='primary' size="small" aria-label="add"
                                            onClick={openChat ? handleChatDrawerClose : handleChatDrawerOpen}
                                            className='chat'
                                            sx={{
                                                ...this.state.fadSx.active,
                                                bgcolor: openChat ? '#1770F6' : 'rgba(255,255,255,.2)',
                                            }}>
                                            <ChatBubbleIcon />
                                            {isNewMessage && <div className="image-indicators">
                                                <label htmlFor="point-1" id="label-1" >
                                                    <span>
                                                        <span></span>
                                                    </span>
                                                </label>
                                            </div>}
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip TransitionComponent={Zoom} title="Screen share" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                        <Fab
                                            color='primary'
                                            size="small"
                                            aria-label="add"
                                            onClick={() => {
                                                if (shareState === 'on') {
                                                    roomClient.disableShare()
                                                } else {
                                                    closeChatTab(false)
                                                    roomClient.enableShare();
                                                }
                                            }}
                                            className='screenShare'
                                            sx={{
                                                ...this.state.fadSx.active,
                                                bgcolor: screenShare ? '#1770F6' : 'rgba(255,255,255,.2)',
                                            }}>
                                            <ScreenShareIcon />
                                        </Fab>
                                    </Tooltip>

                                    <Tooltip TransitionComponent={Zoom} title="More options" arrow placement={this.state.fadSx.active?.mx ? "bottom" : "left"}>
                                        <Fab color='primary' size="small" aria-label="add" sx={{
                                            ...this.state.fadSx.active,
                                            bgcolor: /* screenShare ? '#1770F6' : */ 'rgba(255,255,255,.2)',
                                        }}
                                            onClick={this.handleClick}
                                            aria-controls={Boolean(this.state.anchorElMore) ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(this.state.anchorElMore) ? 'true' : undefined}
                                        >
                                            <MoreVertIcon />
                                        </Fab>
                                    </Tooltip>
                                </>}
                                {/* <Fab color='primary' size="medium" aria-label="add" sx={{
                        ...this.state.fadSx.active,
                        bgcolor: 'rgba(255,255,255,.2)'
                    }}>
                        <AlbumOutlinedIcon />
                    </Fab>
                    <Fab color='primary' size="medium" aria-label="add" sx={{
                        ...this.state.fadSx.active,
                        bgcolor: 'rgba(255,255,255,.2)'
                    }}>
                        <CallToActionIcon />
                    </Fab> */}
                                {/* <Fab color='primary' size="medium" aria-label="add" sx={{
                        ...this.state.fadSx.active,
                        bgcolor: 'rgba(255,255,255,.2)'
                    }}
                        onClick={this.handleClick}
                        aria-controls={Boolean(this.state.anchorElMore) ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(this.state.anchorElMore) ? 'true' : undefined}
                    >
                        <MoreVertIcon />
                    </Fab> */}
                            </>
                        )}
                        {roomClient?._role !== 'consumer' && meetType !== 'meet' ?
                            <Fab color="error" size="small" aria-label="add"
                                // onClick={() => window.location.href = "/"}
                                sx={{
                                    ...this.state.fadSx.active,
                                }}

                                onClick={this.handleClickEnd}
                                // onClick={() => this.setState({ openEndWeb: true })}

                                
                                aria-controls={Boolean(this.state.anchorElEnd) ? 'end-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(this.state.anchorElEnd) ? 'true' : undefined}
                            >
                                <CallEndIcon />
                            </Fab>
                            :
                            <Fab color="error" size="small" aria-label="add"
                                onClick={() => this.endMeet()}
                                sx={{
                                    ...this.state.fadSx.active,
                                }}
                            >
                                <CallEndIcon />
                            </Fab>
                            
                        }
                    </Grid>
                </Draggable>

                <DevicesMenu
                    anchorElDevices={this.state.anchorElDevices}
                    handleCloseDevices={() => this.setState({ anchorElDevices: false })}
                    type={this.state.deviceMenu.type}
                    title={this.state.deviceMenu.title}
                    deviceMenuSx={this.state.deviceMenuSx}
                    transformOrigin={this.state.transformOrigin}
                    anchorOrigin={this.state.anchorOrigin}
                    roomClient={roomClient}
                />

                <ManageAllExternelCases
                    roomClient={roomClient}
                    handleEnableMic={this.handleEnableMic}
                    handleEnableCam={this.handleEnableCam}
                />

                {/* Menu for end webinar button. */}
                <Menu
                    anchorEl={this.state.anchorElEnd}
                    id="end-menu"
                    open={Boolean(this.state.anchorElEnd)}
                    onClose={this.handleCloseEnd}
                    onClick={this.handleCloseEnd}
                    PaperProps={{
                        elevation: 0,
                        sx: this.state.menuBefore
                    }}
                    transformOrigin={this.state.transformOrigin}
                    anchorOrigin={this.state.anchorOrigin}
                >
                    <MenuItem
                        onClick={() => this.setState({ openEndAll: true })}
                        sx={{
                            fontSize: 14,
                        }}>End for all</MenuItem>
                    <MenuItem
                        onClick={() => this.setState({ openLeave: true })}
                        sx={{
                            fontSize: 14,
                        }}>Leave</MenuItem>
                </Menu>

                {/* End webinar modal */}
                <Modal
                    open={this.state.openEndAll}
                    // handleClose={() => this.setState({ openEndAll: false })}
                    // onClose={() => this.setState({openEndWeb: false})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#222222',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        borderRadius: '10px',
                        p: 4,

                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: '10px',
                        }}>
                            <IconButton sx={{
                                color: '#D3232F',
                                bgcolor: "rgba(211, 35, 47, 0.1)",
                                // fontSize: '24px',
                                fontSize: '30xp',
                                height: '10vh',
                                width: '10vh',
                                "&:hover": {
                                    color: '#D3232F',
                                    bgcolor: "rgba(211, 35, 47, 0.1)",
                                }
                            }}>
                                <LocalPhoneOutlinedIcon />
                            </IconButton>

                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#FFFFFF', mt: 2 }}>
                                Do you want to end this session ?
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ color: '#CCCCCC', mt: 2 }}>
                                Room will be closed for everyone
                            </Typography>

                            <Grid xs={12} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                                width: '100%'
                            }}>
                                <LoadingButton
                                    loading={this.state.endSuccessLoading ? true : null}
                                    color='error'
                                    loadingPosition="center"
                                    // startIcon={<SaveIcon />}
                                    variant="contained"
                                    onClick={(e) => this.endWebinar(e)}
                                    sx={{
                                        ml: 1,
                                        borderRadius: '10px',
                                        width: '100%',
                                        textTransform: 'none',
                                    }}
                                >
                                    End for all
                                </LoadingButton>
                                <LoadingButton
                                    loadingPosition="center"
                                    onClick={() => this.setState({ openEndAll: false })}
                                    sx={{
                                        ml: 1,
                                        borderRadius: '10px',
                                        width: '100%',
                                        textTransform: 'none',
                                        bgcolor: '#454545',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            bgcolor: '#454545',
                                            color: '#FFFFFF'
                                        }
                                    }}
                                >
                                    Cancel
                                </LoadingButton>
                            </Grid>
                        </Box>
                    </Box>
                </Modal>

                {/* Leave webinar modal */}
                <Modal
                    open={this.state.openLeave}
                    // handleClose={() => this.setState({ openEndAll: false })}
                    // onClose={() => this.setState({openEndWeb: false})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: '#222222',
                        // border: '2px solid #000',
                        boxShadow: 24,
                        borderRadius: '10px',
                        p: 4,

                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: '10px',
                        }}>
                            <IconButton sx={{
                                color: '#D3232F',
                                bgcolor: "rgba(211, 35, 47, 0.1)",
                                // fontSize: '24px',
                                fontSize: '30xp',
                                height: '10vh',
                                width: '10vh',
                                "&:hover": {
                                    color: '#D3232F',
                                    bgcolor: "rgba(211, 35, 47, 0.1)",
                                }
                            }}>
                                <LocalPhoneOutlinedIcon />
                            </IconButton>

                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#FFFFFF', mt: 2 }}>
                                Are you sure you want to leave
                                webinar?
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ color: '#CCCCCC', mt: 2 }}>
                                You will out form room
                            </Typography>

                            <Grid xs={12} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: 3,
                                width: '100%'
                            }}>
                                <LoadingButton
                                    loading={null}
                                    color='error'
                                    loadingPosition="center"
                                    // startIcon={<SaveIcon />}
                                    variant="contained"
                                    onClick={() => window.location.href = createRedirectPath("/")}
                                    sx={{
                                        ml: 1,
                                        borderRadius: '10px',
                                        width: '100%',
                                        textTransform: 'none',
                                    }}
                                >
                                    Leave
                                </LoadingButton>
                                <LoadingButton
                                    loadingPosition="center"
                                    onClick={() => this.setState({ openLeave: false })}
                                    sx={{
                                        ml: 1,
                                        borderRadius: '10px',
                                        width: '100%',
                                        textTransform: 'none',
                                        bgcolor: '#454545',
                                        color: '#FFFFFF',
                                        '&:hover': {
                                            bgcolor: '#454545',
                                            color: '#FFFFFF'
                                        }
                                    }}
                                >
                                    Cancel
                                </LoadingButton>
                            </Grid>
                        </Box>
                    </Box>
                </Modal>

                {/* Show steps on permission block. */}
                <MicAndVideoPermissionBlock open={this.state?.permissionBlock} close={() => this.setState({ permissionBlock: false })} />

                {/* Show more icon menu options. */}
                <MoreOptionsMenu
                    anchorElMore={this.state.anchorElMore}
                    handleClose={this.handleClose}
                    deviceMenuSx={this.state.deviceMenuSx}
                    transformOrigin={this.state.transformOrigin}
                    anchorOrigin={this.state.anchorOrigin}
                />
            </>
        )
    }
}
const mapStateToProps = (state) => {
    const { meetType } = state.webinarInfo;
    const micAndCamPermissions = state.micAndCamPermissions;
    const { promoteToHost } = state.media;
    const { webcamInProgress } = state.me;
    const { virtualBackground, vbImageUrl } = state.virtualBackground;
    const peersArray = Object.keys(state.peers);
    
    return { meetType, micAndCamPermissions, promoteToHost, webcamInProgress, peersCount: peersArray.length, virtualBackground, vbImageUrl };
};

const SideBarContainer = connect(
    mapStateToProps,
    null,
    null,
    {
        areStatesEqual: (next, prev) => {
            return (
                prev.webinarInfo.meetType === next.webinarInfo.meetType
                && prev.micAndCamPermissions.mic === next.micAndCamPermissions.mic
                && prev.media.promoteToHost === next.media.promoteToHost
                && prev.me.webcamInProgress === next.me.webcamInProgress
                && prev.peers === next.peers
                && JSON.stringify(prev.virtualBackground) === JSON.stringify(next.virtualBackground)
            );
        }
    }
)(SideBar);

export default memo(SideBarContainer);