import bowser from 'bowser';
import Logger from './Logger';

const logger = new Logger("DeviceInfo");

export default function deviceInfo() {

    const ua = navigator.userAgent;
    const browser = bowser.getParser(ua);
    const supportedVersions = {
        chrome: '122.0',
        firefox: '125.0',
        safari: '17.4',
        opera: '109.0',
    'microsoft edge': '122.0',
    };

    const currentVersion = parseFloat(browser.getBrowserVersion());
    const browserName = browser.getBrowserName().toLowerCase();
    const isIPhone = /iPhone/.test(ua);
 

    if (supportedVersions.hasOwnProperty(browserName)) {
        
        logger.debug("@deviceInfo", browserName, "is supported with version", currentVersion, "browser detail",browser);
        if (currentVersion >= parseFloat(supportedVersions[browserName])|| (browserName === 'chrome' && currentVersion >= 115.0)) {
            return {
                flag: browserName,
                name: browser.getBrowserName(),
                version: currentVersion,
                isSupported: true,
                isChrome: browserName === 'chrome',
                isIPhone: isIPhone

            };
        }
        return {
            flag: browserName,
            name: browser.getBrowserName(),
            version: currentVersion,
            isSupported: false,
            isIPhone: isIPhone
            
        };
    } else {
        logger.debug("@deviceInfo", browserName, "is not supported with version", currentVersion);
        return {
            flag: browserName,
            name: browser.getBrowserName(),
            version: currentVersion,
            isSupported: false,
            isUnknown: true,
            isChrome: browserName === 'chrome',
            isIPhone: isIPhone

        };
    }
}
