import * as React from 'react';
import io from 'socket.io-client';
import LeadsSection from '../../sections/common/leads';
import { renderErrorMessage } from '../../components/ToastNotification';
import { LeadsContext } from '../../context/leadsContext';
import { ToastContainer } from 'react-toastify';
import LeadsTable from '../../sections/common/leads/leadsTable';
import { Box, Typography, Tabs, Tab as ActualTabs, styled, Button } from '@mui/material';
import CardsSection from '../../sections/common/leads/cardsSection';

import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { connectWs, wsEmit } from '../../lib/socketIo';
import { getItem } from '../../lib/cookiesManager';
import jwtDecode from 'jwt-decode';
import { SOCKET_ENVENTS } from '../../config/Enums';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{}}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Tab = styled(ActualTabs)(({ theme }) => ({
    color: theme.palette.grey[300]
}))

const WelcomeSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '-15px',
    [theme.breakpoints.down("sm")]: {
        flexDirection: 'column',
        gap: '10px'
    }
}))

export default function LeadsDashboard() {

    const [value, setValue] = React.useState(0);
    const [socket, setSocket] = React.useState(null);
    const [name, setName] = React.useState(0);
    const [list, setList] = React.useState([]);
    const [listLoading, setListLoading] = React.useState(false);
    const [/* from */, setFrom] = React.useState(false);
    const [/* to */, setTo] = React.useState(false);
    const [intervalId, setIntervalId] = React.useState("");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const navigate = useNavigate()

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchList(socket, value, newPage, rowsPerPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        fetchList(socket, value, 0, +event.target.value)

    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
        fetchList(socket, newValue)
    };

    function notifyMe() {
        try {
            if (!("Notification" in window)) {

                alert("This browser does not support desktop notification");

            } else if (Notification.permission !== "denied") {

                // We need to ask the user for permission
                Notification.requestPermission().then((permission) => {

                    console.log(permission)
                    /*   // If the user accepts, let's create a notification
                      if (permission === "granted") {
                          const notification = new Notification("Hi there 1!");
                      } */
                });
            }

        } catch (error) {
            console.log(error)
            renderErrorMessage("Desktop notification is stoped!" + error?.message)
        }
    }

    const connectSocket = (newSocket) => {
        try {
            // const newSocket = await connectWs();


            wsEmit(newSocket, SOCKET_ENVENTS?.NEW_USER, {})

            setSocket(newSocket);

            fetchList(newSocket);

            // const intervalID = setInterval(() => fetchList(newSocket), 5000);

            // setIntervalId(intervalID)

            return () => newSocket.close();

        } catch (error) {
            console.log(error);
            renderErrorMessage("Somthing went wrong")
        }
    }

    const getName = () => {
        try {
            const token = getItem("token");
            if (token) {
                const { name = "" } = jwtDecode(token);
                // const splitName = name.split(" ")[0]
                const formateName = name?.[0].toUpperCase() + name.slice(1)
                setName(formateName)
            }
        } catch (error) {
            console.log(error);
            renderErrorMessage("Somthing went wrong")
        }
    }

    const fetchList = async (newSocket, value = 0, p = page, size = rowsPerPage, from = "", to = "") => {
        try {
            setListLoading(true)
            let payload = { page: p, size };

            if (from) payload.fromDate = from
            if (to) payload.toDate = to
            if (value === 1) payload.status = "pending"
            if (value === 2) payload.status = "running"
            if (value === 3) payload.status = "rejected"
            if (value === 4) payload.status = "disconnected"
            if (value === 5) payload.status = "completed"

            const { callList = [] } = await wsEmit(newSocket, SOCKET_ENVENTS?.FETCH_CALL_LIST, payload);
            console.log(callList)

            setList(callList)
        } catch (error) {
            console.log(error);
            renderErrorMessage("Somthing went wrong")
        } finally {
            setListLoading(false)
        }
    }

    const handleOnChangeDate = (value) => {
        if (value) {
            const from = value[0]
            const to = value[1]
            setFrom(from)
            setTo(to);

            fetchList(socket, value, page, rowsPerPage, from, to)

        }
    }

    React.useEffect(() => {
        let isMounted = true;

        const { url, token } = connectWs()

        const newSocket = io(url, {
            path: "/meet-api/socket.io",
            // autoConnect: false,
            transports: ['websocket', 'polling'],
            auth: {
                token
            }
        }
        )

        newSocket.on('connect_error', (error) => {
            console.log('Connection Error:', error.message);
            if (error?.message === "UNAUTHORIZED") {
                navigate("/");
            }
            // Handle the error appropriately here
        });

        newSocket.on("updateCallInfo", ({ callInfo }) => {
            console.log(callInfo)
            // setAllData(callStatusCounts);
            const { id, status } = callInfo;
            if (status === "pending") {
                setList((prev) => [callInfo, ...prev]);
            }
            setList((prev) => {
                return prev.map((item) => {
                    if (item?.id === id) {
                        return callInfo
                    }
                    return item;
                })
            });
        });

        newSocket.on('disconnect', (error) => {
            console.log(error)
            // 
        })
        newSocket.on('close', (error) => {
            console.log(error)
            // 
        })

        notifyMe();

        getName()

        connectSocket(newSocket);

        return () => {

            isMounted = false;
            // alert(newSocket)
            clearInterval(intervalId)
            if (newSocket) newSocket?.close();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px',
            padding: '15px'
        }}>
            <ToastContainer />

            <LeadsContext.Provider value={{ socket, name }} >
                <LeadsSection />

                <WelcomeSection>
                    <Typography sx={{
                        typography: 'h3',
                        color: 'grey.700',
                        textAlign: 'center',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                    }}>
                        Hi, Welcome {name}
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon={"fluent-emoji:waving-hand"} />
                        </span>
                    </Typography>

                    <DateRangePicker
                        placement={"bottomEnd"}
                        // label={"Select Date Range: "}
                        placeholder={"Select Date Range"}
                        onChange={handleOnChangeDate}
                    />

                </WelcomeSection>


                <Box>
                    <CardsSection />
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}>
                    <Typography variant='h5' sx={{ color: 'grey.600' }}>
                        Report
                    </Typography>

                    <Box sx={{ width: '100%', border: '0.25px solid', borderRadius: '6px' ,borderColor: 'divider'}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between', mr: '10px', }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                textColor="primary"
                                indicatorColor="primary"
                                sx={{
                                    '& .MuiTab-root': {
                                        color: 'text.primary',
                                        fontWeight: 'bold',
                                    },
                                    '& .Mui-selected': {
                                        color: 'primary.main',
                                    },
                                }}
                            >
                                <Tab label="All" {...a11yProps(0)} />
                                <Tab label="Waiting Lobby" {...a11yProps(1)} />
                                <Tab label="Running" {...a11yProps(2)} />
                                <Tab label="Junk" {...a11yProps(3)} />
                                <Tab label="Disconnected" {...a11yProps(4)} />
                                <Tab label="Completed" {...a11yProps(4)} />
                            </Tabs>

                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button size='small' variant='outlined' onClick={() => navigate("/reports")}>
                                    Detail Report
                                </Button>
                            </Box>

                        </Box>
                        <CustomTabPanel value={value} index={value}>
                            <LeadsTable
                                data={list}
                                fetchList={fetchList}
                                value={value}
                                listLoading={listLoading}
                            />
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={list.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                sx={{
                                    color: 'grey.600',
                                    "& .MuiPaginationItem-root": {
                                        color: "#fff"
                                    },
                                    "& .MuiButtonBase-root": {
                                        color: "#fff"
                                    }
                                }}

                            />
                        </CustomTabPanel>
                    </Box>
                </Box>
            </LeadsContext.Provider >
        </Box>
    );
}

