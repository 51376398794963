import { Grid, Box, Typography, TextField, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import LoginIcon from '@mui/icons-material/Login';
import randomString from 'random-string';
import UrlParse from 'url-parse';
import { LoadingButton } from '@mui/lab';
import { renderErrorMessage } from '../../../components/ToastNotification';
import { getItem, setItem } from '../../../lib/cookiesManager';
import { joinWebianrCheck } from '../../../services/commonApis';
import jwt from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { validateText } from '../../../utils/common';
import MeetLogoSvg from '../../../assets/svgs/webinarLogo';
import LoaderWithMessage from './LoaderWithMessage'
import { axiosPostCall } from '../../../services/apisCall';

export default function JoinWebinar() {
    const [roomId, setRoomIdINput] = useState(randomString({ length: 8 }).toLowerCase());
    const [peerId,] = useState(randomString({ length: 8 }).toLowerCase());
    const [joinLoading, setJoinLoading] = useState(false);
    const [name, setName] = useState(getItem("name") || "");
    const [nameErr, setNameErr] = useState(false);
    const [updateToCookies, setUpdateToCookies] = useState(false);
    const [bId, setbId] = useState("");
    const [e2eKey, setE2eKey] = useState("");
    const [loaderMessage, setLoaderMessage] = useState("");
    const navigate = useNavigate();
    const [intervalId, setIntervalId] = useState(null);
    const [hasJoined, setHasJoined] = useState(false);

    const checkStatus = async () => {
        try {

            const response = await axiosPostCall("waitForStart", { roomId });

            if (!response) {
                throw new Error("Empty response received");
            }

            if (response.meetingStatus) {
                const { meetingStatus } = response;
                if (meetingStatus === 'active' && !hasJoined) {
                    try {
                        const { redirectURL, state } = await joinWebianrCheck(roomId, name, peerId);
                        if (redirectURL) {
                            setHasJoined(true);
                            clearInterval(intervalId);
                            navigate(redirectURL, { state });
                        } else {
                            renderErrorMessage("Invalid link!");
                        }
                    } catch (error) {
                        renderErrorMessage("Error joining webinar");
                    }
                }
            } else {
                throw new Error("Missing 'meetingStatus' property in the response");
            }
        } catch (error) {
            throw new Error("Error checking meeting status");

        }
    };

    const joinWebinar = async (e) => {
        if (e) e.preventDefault();
        setJoinLoading(true);
        try {
            if (updateToCookies) setItem("name", name)
            let token = getItem("token");
            let pId = null;
            let userName = name || "";
            if (token) {
                let info = jwt(token);
                pId = info?.email;
                if (!userName && info?.name) userName = info.name;
                setName(userName);
            }
            if (!userName) {
                setJoinLoading(false);
                return renderErrorMessage("Enter your name!");
            }
            if (!validateText(userName)) {
                setJoinLoading(false);
                setNameErr(true);
                return renderErrorMessage("Please enter a valid name!");
            }
            if (!pId) pId = peerId;
            if (bId) pId = bId;

            let { redirectURL, state, message } = await joinWebianrCheck(roomId, userName, pId) || {};
            if (message) {
                setLoaderMessage(message);
                setJoinLoading(false);
                // setInterval(checkStatus, 5000);
                const id = setInterval(checkStatus, 5000);
                setIntervalId(id);
                return;
            }
            if (redirectURL) {
                if (e2eKey) {
                    state.e2eKey = e2eKey;
                    redirectURL = redirectURL + `&key=${encodeURIComponent(e2eKey.toString())}`
                }

                if (state?.waitingRoom) return navigate("/waiting_room", { state, redirectURL });

                navigate(redirectURL, { state });
                return setJoinLoading(false)
            } else {
                setJoinLoading(false)
                return renderErrorMessage("Invalid link!")
            }

        } catch (error) {
            setJoinLoading(false)
            return renderErrorMessage(error?.message);
        }
    }

    // Change name on join webinar popup.
    const changeName = (value) => {
        setName(value);
        setUpdateToCookies(true)
    }

    // Redirect to login page.
    // const loginRedirect = () => {
    //     navigate('/login', { state: { peerId, name, roomId } });
    // }


    useEffect(() => {
        const urlParser = new UrlParse(window.location.href, true);
        const urlRoomId = urlParser?.query?.roomId;
        const urlbId = urlParser?.query?.bId;
        const key = urlParser?.query?.key;
        if (urlbId) setbId(urlbId);
        if (key) setE2eKey(key);
        if (urlRoomId) return setRoomIdINput(urlRoomId);
        setJoinLoading(true)
        return renderErrorMessage("Invalid link!")
    }, []);

    // Bypass the join page check for login user.
    useEffect(() => {
        const urlParser = new UrlParse(window.location.href, true);
        const urlRoomId = urlParser?.query?.roomId;
        if (getItem("sessionToken") && urlRoomId === roomId) {
            joinWebinar();
        }
        // eslint-disable-next-line
    }, [roomId]);

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);

    return (
        <Grid item={true} xs={12} sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'background.default',
            height: '100vh',
            boxShadow: '0px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <ToastContainer />
            <Box sx={{
                backgroundColor: 'card.main',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                height: loaderMessage ? '560px' : '423px',
                width: '523px',
                flexDirection: 'column',
                borderRadius: '10px'
            }}>


                <MeetLogoSvg width='auto' />

                <Typography sx={{
                    fontWeight: 700,
                    fontSize: '25px',
                    color: "grey.70",
                    mb: 1,
                    mt: 2
                }}>
                    Join Meeting
                </Typography>
                <Typography sx={{
                    fontWeight: 400,
                    fontSize: '16px',
                    color: 'grey.400',
                    mb: 2
                }}>
                    Please enter details and join the meeting
                </Typography>

                <Box sx={{
                    background: 'rgba(220, 160, 0, 0.1)',
                    borderRadius: '20px',
                    paddingRight: '10px'
                }}>
                    <Typography sx={{
                        color: '#DCA000',
                        fontSize: '12px',
                        ml: 1
                        // mb: 2
                    }}> <IconButton sx={{ '&:hover': { backgroundColor: "transparent", } }}>
                            <WarningAmberIcon sx={{ color: '#DCA000' }} />
                        </IconButton>Note: Login required to join as a host.</Typography>
                </Box>

                {loaderMessage && <LoaderWithMessage message={loaderMessage} />}

                <Box sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '90%',
                    flexDirection: 'column',
                    mb: 4,
                    mt: 2
                }}>
                    <TextField
                        sx={{
                            width: '100%',
                            mt: 1,
                            backgroundColor: 'grey.1000',
                            borderRadius: '10px',
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: 'grey.800',
                                }
                            },
                            "& .MuiFormLabel-root": {
                                "&.Mui-focused": {
                                    color: 'grey.600',
                                }
                            }
                        }}
                        inputProps={{ sx: { color: "grey.400" } }}
                        label="Name"
                        focused
                        className="inputRounded"
                        id="filled-hidden-label-small"
                        placeholder="Enter your name "
                        value={name}
                        onChange={(e) => changeName(e.target.value)}
                        onClick={() => setNameErr(false)}
                        error={nameErr ? true : false}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '90%',
                    mb: 2
                }}>
                    <LoadingButton
                        loading={joinLoading ? true : null}
                        color='primary'
                        loadingPosition="center"
                        startIcon={<LoginIcon />}
                        variant="outlined"
                        id={peerId}
                        onClick={(e) => joinWebinar(e)}
                        sx={{
                            // ml: 1,
                            borderRadius: '10px',
                            width: '100%',
                            textTransform: 'none'
                        }}
                    >
                        Join Now
                    </LoadingButton>
                </Box>

                {/* {!getItem("sessionToken") && <Link onClick={() => loginRedirect()}> Check here to Login &amp; Join Meeting</Link>} */}
            </Box>

        </Grid>
    )
}
