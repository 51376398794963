import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Modal, Box, Grid, Typography, Button } from '@mui/material';
import { enableVirtualBackground } from "../../../../redux/actions/stateActions";
import RoomContext from '../../../../context/RoomContext';
import { configFile } from '../../../../config';
import CloseIcon from '@mui/icons-material/Close';

const { imagesUrl: {  
  blurBackground, 
} } = configFile;

const MainOuterBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "600px",
  // height: "500px",
  backgroundColor: '#2C2D2F',
  padding: "35px 30px 30px",
  display: "flex",
  flexDirection: "column",
  // alignItems: "flex-end",
  borderRadius: "12px",
  gap: "10px",
  justifyContent: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%"
  }
}));

  const vcImagesData = Object.keys(configFile.imagesUrl)
  .filter(key => key.startsWith('thumbnail'))
  .map((key, index) => ({
    thumbnail: configFile.imagesUrl[`thumbnail${index + 1}`],
    fullSize: configFile.imagesUrl[`virtualBackground${index + 1}`]
  }));

const VirtualBackground = ({ open, handleClose, webcamState }) => {
  const dispatch = useDispatch();
  const { virtualBackground, vbImageUrl } = useSelector(state => state?.virtualBackground);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  // const [vcImages, /* setVcImages */] = useState([virtualBackground1, virtualBackground2, virtualBackground3, virtualBackground4];
  const [vcImages] = useState(vcImagesData);  // Use all thumbnails and images

  const [activeImage, setActiveImage] = useState(vbImageUrl);
  const [blur, setBlur] = useState(virtualBackground === "blur");
  const [vb, setVb] = useState(virtualBackground);
  const roomClient = useContext(RoomContext);

  const handleImageClick = (imageUrl, index) => {
    setBlur(false);
    setActiveImageIndex(index);
    setActiveImage(imageUrl);
    setVb("on");
  };

  const handleBlur = () => {
    setBlur(true);
    setActiveImageIndex(null);
    setVb("blur");
  }

  const setVirtualBackground = async () => {
    dispatch(enableVirtualBackground({
      virtualBackground: vb,
      vbImageUrl: blur ? "" : activeImage
    }));
    if (webcamState === "on") await roomClient.updateWebcamStream(vb, activeImage);
    return handleClose(false);
  }

  const removeFilter = async () => {
    dispatch(enableVirtualBackground({
      virtualBackground: "off",
      vbImageUrl: ""
    }));
    if (webcamState === "on") await roomClient.updateWebcamStream("off", "");
    return handleClose(false);
  }

  return (
    <Modal
      open={open}
      aria-labelledby="image-selector-modal-title"
      aria-describedby="image-selector-modal-description"
    >
      <MainOuterBox>
        <Box sx={{
          position: 'absolute',
          top: '10px',
          right: "10px"
        }}>

          <CloseIcon
            sx={{
              height: "30px",
              width: "30px",
              p: '4px',
              color: "#A1A2A3",
              marginRight: '10px',
            }}
            onClick={() => handleClose(false)}>
          </CloseIcon>
        </Box>

        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: "8px"
        }}>
          <Typography variant='h4' component={'h4'}>Virtual Background</Typography>
        </Box>

        <Typography variant='body1'>Blur Background</Typography>

        <Box sx={{
          ml: '8px',
          mb: '8px'
        }}>
          <img
            style={{
              height: "50px",
              width: "50px",
              border: blur ? "2px solid blue" : "",
              cursor: 'pointer',
            }}
            src={blurBackground}
            onClick={() => handleBlur()}
            alt={"blur"}
          />
        </Box>

        <Typography variant='body1'>Select Image for your Background</Typography>
        <Box
          sx={{
            height: '300px',
            overflowY: 'auto',
            width: "100%",
            padding: '0 8px',
          }}
        >
          <Grid
            container
            spacing={2}
          >
            {vcImages.map((vcImage, index) => (


              <Grid item xs={3} key={index} sx={{
                borderRadius: '6px',
                overflow: 'hidden',
              }}>
                <img
                  style={{
                    // height: "150px",
                    // width: "300px",
                    border: activeImageIndex === index ? "2px solid blue" : "",
                    cursor: 'pointer',
                    borderRadius: '6px',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                  src={vcImage.thumbnail}
                  onClick={() => handleImageClick(vcImage.fullSize, index)}
                  alt={`${index}`}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'flex-end',
          paddingTop: '20px',
        }}>
          <Button
            onClick={() => removeFilter()}
            variant='outlined'
            color='error'
          >Remove Filters</Button>
          <Button
            onClick={() => { setVirtualBackground(); handleClose(false); }}
            variant='contained'
          >Update Image</Button>
        </Box>

      </MainOuterBox>
    </Modal>
  );
};

export default VirtualBackground;
